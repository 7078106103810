.comment-reply-row {
  height: 0;
}

.comment-full-link{
  margin-right: 20px;
  color: #6ca211;
  font-size: 14px;
  font-weight: 500;
  &:hover{
    color: rgba(108, 162, 17, 0.8);
    cursor: pointer;
  }
}

.comment-user-name {
  min-height: 22px;
  a:link, a:visited {
    color: #6ca211;
  }
  a:hover {
    color: #000;
  }

  .look-ip {
    margin: 0 4px;
    color: #444;
    &:hover {
      cursor: pointer;
    }
    i.active {
      color: #6ca211;
    }
  }
  .user-ip {
    display: none;
    color: #6ca211;
    position: absolute;
    width: 128px;
    background: white;
    border-radius: 7px;
    text-align: center;
    z-index: 2;
    box-shadow: 2px 2px 0px;
  }
}

.comment-shop-cell, .comment-user-cell {

}

.comment-user-cell {
  background: #fff;
  border-radius: 0 0 10px 0;
}

.comment-shop-title a:link, .comment-shop-title a:visited {
  font-family: Arial;
  font-size: 16px;
  color: #6ca211;
  font-weight: bold;
  display: inline-block;
  padding-left: 15px;
  margin: 0;
  text-decoration: none;
  letter-spacing: 1px;
}

.comment-body {
  width: 100%;
  border-radius: 20px;
  background-color: #D5F2CD;
  padding: 23px 13px;
  text-align: left;
  margin: 0 auto;
  background: #D5F2CD;
  position: relative;
  border: 2px solid #ffffff;
  padding-bottom: 28px;
  //word-break: break-all;
  overflow: hidden;

  .container {
    min-height: 80px;
  }
}

.comment-entity {

  .info-section {
    //max-width: 95%;
    border-top: 1px dashed #fff;
    position: relative;
    // bottom: -10px;
    left: 0;
    height: 20px;
    padding-top: 6px;
    margin-top: 6px;
    //float: right;

    .time {
      color: rgba(0, 0, 0, .5);;
      font-size: 13px;
    }
  }

  .comment-parent-box, .comment-child-box {
    /* max-width: 580px;*/
    min-height: 100px;
    margin: 20px 0;
    float: none;
  }

  .comment-child-box {
    margin-left: 38px;
    border-left: 2px dashed #B6DEC4;
  }
}

.comment-reply {
  background: #b6dec4;
  border-radius: 55%;
  position: relative;
  top: -22px;
  border: 2px solid;
  left: 18px;
  z-index: 2;
  border-top-color: #ffffff;
  border-left-color: #ffffff;
  width: 40px;
  height: 40px;
  font-size: 20px;
  color: white;
  text-align: center;
  padding-top: 6px;

  &:hover {
    background-color: #D5F2CD;
    color: #8bbb9c;
    border-color: #8bbb9c;
    cursor: pointer;

  }

}

.child-comments {

  .comment-shop-title {
    visibility: hidden;
  }
  .row {
    background: #B6DEC4;
  }

  .point-marker.active {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    position: absolute;
    left: -44px;
    top: 42%;
    background: #B6DEC4;
    box-shadow: 0px 0px 2px #9ec5ab;
  }

  .child-comment-entity {
    position: relative;
    margin-bottom: 16px;

    .info-section {
      max-width: 100%;
      border-top: 1px dashed #fff;
      position: relative;
      /* bottom: -10px;*/
      left: 0;
      height: 20px;
      padding-top: 2px;

      .time {
        color: rgba(0, 0, 0, .5);;
        font-size: 13px;
      }
    }
  }

  .child-comment-entity:hover .point-marker {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    position: absolute;
    left: -46px;
    top: 42%;
    background: #B6DEC4;
    box-shadow: 0px 0px 2px #9ec5ab;
    opacity: 1;
    transition: opacity 0.2s linear;
  }

  .comment-body:hover {

    box-shadow: 0 0 5px #D5F2CD;
    cursor: pointer;
  }

  .point-marker {
    opacity: 0;

  }
  margin-left: 20px;
  margin-top: 10px;

}

ul.share-link {
  display: table;
  margin: 0;
  padding: 0;
  float: right;
  z-index: 3;
  position: relative;

  a:link, a:visited {
    color: #6ca211;
  }

  li {
    display: table-cell;
    list-style: none;
    vertical-align: middle;
    padding: 0 5px;
    height: 38px;
    text-align: center;
  }
  .just-link-target {
    display: none;
  }
}

.comment-selected-highlight {
  border-color: #6ca211;
  transition: all 2s;
}

.img-box {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  background: #e8f5e9;
  border: 2px solid rgba(123, 179, 31, 0.57);
  margin: 5px;
  border-radius: 10%;
  background-size: cover;

  .img-delete {
    position: absolute;
    right: -46px;
    top: 9px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: rgb(0, 0, 0);
    color: #fff;
    border-radius: 8px;
    &:hover {
      cursor: pointer;
      background: #FF0000;
    }
  }

  &:after {
    content: "x";
    position: absolute;
    width: 14px;
    height: 14px;
    color: #000;
    line-height: 14px;
    top: 18px;
    right: -18px;
  }

  //Progress bar for Uploading
  .progress-bar {
    height: 10px;
    background: #7bb31f;
    background: -webkit-linear-gradient(left, #7bb31f, #ffb000); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(right, #7bb31f, #ffb000); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, #7bb31f, #ffb000); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, #7bb31f, #ffb000); /* Standard syntax */
    position: absolute;
    z-index: 2;
    top: 40%;
    left: 0;
  }

}

//Styles for attached images
.attachment-list {
  margin-top: 10px;
  .img-box {
    border: none;
    float: left;
    &:hover {
      cursor: pointer;
      border: 2px solid #fff;
    }
    &:after {
      display: none;
    }
  }
}

.fa.cam {
  color: #b6dec4;
  font-size: 16px;
  position: absolute;
  top: 23px;
  right: 16px;
  width: 38px;
  height: 38px;
  text-align: center;
  line-height: 30px;
  z-index: 2;
  padding: 3px;
  background: #ffffff;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 27px;

  &:hover {
    cursor: pointer;
    color: #7bb31f;
  }
}

.attachment-list .img-box.hovered-images {
  border: 2px solid #fff;
}

//City in comment
.comment-city {
  font-size: 14px;
  color: #000000;
  .fa {
    padding-right: 5px;
  }
}