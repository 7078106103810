.estimate-rating-section{


}
.rating-section{
  margin: 20px 0;
}

.more-shops {
  a {
    text-transform: uppercase;
    display: inline-block;
    padding: 6px 30px;
    border-bottom: 1px solid #fff;
    letter-spacing: 2px;

    &:visited,&:link{
      text-decoration: none;
      color: #fff;
    }
    &:hover{
      color: $mainColorHover;
    }
  }
  position: relative;
  width: 100%;
  left: 0;
  text-align: center;
  bottom: -30px;
  display: block;
}