.search-box, .search-box-general {

  background: #ffffff;
  border-radius: 20px;
  padding: 8px 0 8px 28px;
  font-size: 16px;
  border: 2px solid;
  border-color: #93bb51;
  /*width: 95%;*/
 /* max-width: 580px;*/
 /*margin: 0 auto;*/
  color: #93bb51;

  #search-shop,#search-shop-general {
    display: inline-block;
    height: 32px;
    width: 80%;
    color: #000;
    vertical-align: middle !important;

    input {
      width: 100%;
    }

  }

  label {
    display: inline-block;
    padding-right: 12px;
  }

  .search-button {

    padding-left: 12px;
    border: 2px solid;
    border-radius: 20px;
    padding: 6px 25px;
    background: #93bb51;
    margin-left: 12px;
    color: #fff;

    &:hover {
      cursor: pointer;
      border-color: #93bb51;
      background: #fff;
      color: #93bb51;
    }
  }
  #search-shop {
    display: inline-block;
  }

}

.shop-result-nothing{
  padding: 5px;

}
.tt-menu {
  color: #000;
  width: 100%;
  padding: 5px 0px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #c1dcc3;
  box-shadow: 2px 4px 3px #3e5a0d;
}


.tt-selectable {
  background: #fff;
  display: block;
  font-size: 22px;
  padding: 0 10px;

  &:hover, &.tt-cursor {
    cursor: pointer;
    background: #d8f3da;
    font-weight: 600;
  }

}
.twitter-typeahead{
    width: 100%;
  }