body {
  position: relative;
}

.content-layout {
  max-width: 800px;
}

.logotype {
  margin-left: 20px;
  text-align: center;
  width: 380px;
  display: block
}

.footer {
  background: #f3faf4;
  border-radius: 20px;
  margin: 30px 0 8px 0px;
  padding: 15px;
  border: 1px solid #fff;
}

.about {
  border-right: 1px solid #fff;
  box-shadow: 2px 0px 0px #e8f5e9;
}

.up {
  width: 51px;
  height: 51px;
  display: block;
  background: url('/img/up.png') no-repeat;
  position: fixed;
  bottom: 28px;
  right: 20px;
  border-radius: 50%;
  opacity: .8;

  &:hover {
    opacity: 1;
    cursor: pointer;
    box-shadow: 0px 1px 8px #b9b9b9;
  }
}

.stars-rating-5 {
  background: url('/img/stars_sprint.png') no-repeat;
  width: 96px;
  height: 15px;
  background-position: 0px 0px;
}
.stars-rating-4 {
  background: url('/img/stars_sprint.png') no-repeat;
  width: 96px;
  height: 15px;
  background-position: 0px -17px;
}
.stars-rating-3 {
  background: url('/img/stars_sprint.png') no-repeat;
  width: 96px;
  height: 15px;
  background-position: 0px -34px;
}
.stars-rating-2 {
  background: url('/img/stars_sprint.png') no-repeat;
  width: 96px;
  height: 15px;
  background-position: 0px -51px;
}
.stars-rating-1 {
  background: url('/img/stars_sprint.png') no-repeat;
  width: 96px;
  height: 15px;
  background-position: 0px -68px;
}
.stars-rating-0 {
  background: url('/img/stars_sprint.png') no-repeat;
  width: 96px;
  height: 15px;
  background-position: 0px -85px;
}

/*GOOGLE advertisement block*/
.adv-top-box{
  margin: 10px 0;
}