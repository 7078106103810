.list-shop-cell {
  background: #fff;
  border-radius: 10px;
  border: 2px solid;
  border-color: #e8f5e9;
  margin-top: 20px;
  padding: 0 15px;
  display: block;

  &:hover {
    border-color: #cfe4ae;
  }

  .rating_shop_link {
    margin: 4px 0;
    display: block;
    color: #6ca211;
    &:hover {
      color: #000;
    }
  }
  .shop_name_rating {
    margin: 4px 0;
    color: #000;
    &:hover {
      color: #6ca211;
    }
  }

}

.shops-list-lnk {
  border-radius: 20px;
  width: 62%;
  margin: 0 auto;
  padding: 8px;
  border: 2px solid #fff;
  letter-spacing: 1px;

  a:visited, a:link {
    color: #fff;
  }
  a:hover {
    color: #000;
  }

}