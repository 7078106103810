@font-face {
  font-family: Roboto;
  src: url("/fonts/roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url("/fonts/roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
}

body {
  position: relative;
}

.content-layout {
  max-width: 800px;
}

.logotype {
  margin-left: 20px;
  text-align: center;
  width: 380px;
  display: block;
}

.footer {
  background: #f3faf4;
  border-radius: 20px;
  margin: 30px 0 8px 0px;
  padding: 15px;
  border: 1px solid #fff;
}

.about {
  border-right: 1px solid #fff;
  box-shadow: 2px 0px 0px #e8f5e9;
}

.up {
  width: 51px;
  height: 51px;
  display: block;
  background: url("/img/up.png") no-repeat;
  position: fixed;
  bottom: 28px;
  right: 20px;
  border-radius: 50%;
  opacity: .8;
}

.up:hover {
  opacity: 1;
  cursor: pointer;
  box-shadow: 0px 1px 8px #b9b9b9;
}

.stars-rating-5 {
  background: url("/img/stars_sprint.png") no-repeat;
  width: 96px;
  height: 15px;
  background-position: 0px 0px;
}

.stars-rating-4 {
  background: url("/img/stars_sprint.png") no-repeat;
  width: 96px;
  height: 15px;
  background-position: 0px -17px;
}

.stars-rating-3 {
  background: url("/img/stars_sprint.png") no-repeat;
  width: 96px;
  height: 15px;
  background-position: 0px -34px;
}

.stars-rating-2 {
  background: url("/img/stars_sprint.png") no-repeat;
  width: 96px;
  height: 15px;
  background-position: 0px -51px;
}

.stars-rating-1 {
  background: url("/img/stars_sprint.png") no-repeat;
  width: 96px;
  height: 15px;
  background-position: 0px -68px;
}

.stars-rating-0 {
  background: url("/img/stars_sprint.png") no-repeat;
  width: 96px;
  height: 15px;
  background-position: 0px -85px;
}

/*GOOGLE advertisement block*/
.adv-top-box {
  margin: 10px 0;
}

#scrollable-dropdown-menu {
  margin-top: 10px;
}

label {
  display: block;
}

.label-inline {
  display: inline-block;
}

input {
  display: inline-block;
  margin-left: 0px;
  padding-left: 0px;
  position: relative;
  border-radius: 8px;
  padding: 4px;
  border: 1px solid #AACEAE;
}

.field-w-4 {
  width: 60%;
}

.field-y-3 {
  height: 36px;
}

.add-shop-section {
  max-width: 620px;
}

.add-shop-section .section-content {
  width: 100%;
  min-height: 300px;
  border-radius: 20px;
  background-color: #b6dec4;
  padding: 44px 22px;
  text-align: left;
  margin: 0 auto;
}

.comment-add-section h3 {
  text-align: left;
}

.comment-reply-active {
  background-color: #D5F2CD !important;
  color: #8bbb9c !important;
  border-color: #8bbb9c !important;
  cursor: pointer;
  box-shadow: 2px 1px 0px #8bbb9c;
}

.write-reply-comment-content {
  width: 100%;
  min-height: 100px;
  border-radius: 20px;
  background-color: #b6dec4;
  padding: 23px 13px;
  text-align: left;
  margin: 0 auto;
}

.reply-comment-section {
  display: block;
  max-width: 620px;
  min-height: 100px;
  position: relative;
  text-align: left;
  margin: 0 auto;
}

.write-comment-title {
  font-size: 20px;
  text-transform: uppercase;
  color: #b6dec4;
}

.comment-reply-form {
  display: none;
  position: relative;
}

.comment-reply-form .ver-pointed-line {
  width: 2px;
  height: 100%;
  border-left: 2px dashed #AACEAE;
  display: block;
  position: absolute;
  top: -25px;
  left: 52px;
}

.comment-reply-form legend {
  border: 0;
  margin-left: 48px;
}

.comment-reply-form .reply-comment-section .title-form-comment {
  letter-spacing: 2px;
  color: #fff;
}

.comment-reply-form .reply-comment-section h3 {
  text-align: left;
}

.comment-reply-form .reply-comment-section label {
  display: block;
}

.comment-reply-form .reply-comment-section textarea {
  width: 96%;
  display: block;
}

.comment-reply-form .reply-comment-section textarea:focus, .comment-reply-form .reply-comment-section input[type="text"]:focus, .comment-reply-form .reply-comment-section select:focus {
  border-radius: 8px;
  outline: none;
  border: 1px solid #ffffff;
  box-shadow: 0 0 5px #555;
}

.comment-reply-form .reply-comment-section input[type='text'] {
  display: inline-block;
  margin-left: 0px;
  padding-left: 0px;
  position: relative;
  border-radius: 8px;
  padding: 4px;
  border: 1px solid #AACEAE;
}

.comment-reply-form .reply-comment-section button {
  border-radius: 10px;
  margin-top: 10px;
  min-width: 100px;
  height: 38px;
  text-transform: uppercase;
  background: white;
  border: 2px solid #b6dec4;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: bold;
  color: #555;
  box-shadow: 1px 4px 1px rgba(0, 0, 0, 0.075);
}

.comment-reply-form .reply-comment-section button:hover {
  background: #b6dec4;
  color: #fff;
  border-color: #fff;
}

.teb-reply {
  margin-left: 48px;
}

.alert {
  display: none;
}

.alert.active {
  display: block;
}

.show-overall-estimate {
  font-size: 22px;
}

.overall-estimate {
  float: left;
  color: #ffb000;
  font-size: 22px;
  margin-right: 4px;
}

.rating-reset {
  float: left;
  margin: 0 6px;
  color: #dd0000;
  font-size: 14px;
}

.rating-reset:hover ~ .overall-estimate {
  color: #dd0000;
}

.submit-btn {
  background: #7bb31f;
  /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border: 2px solid #D5F2CD;
}

.submit-btn:hover {
  border: 2px solid #ffffff;
  background: #709c0f;
}

.glyphicon:hover {
  cursor: pointer;
}

.comment-reply-row {
  height: 0;
}

.comment-full-link {
  margin-right: 20px;
  color: #6ca211;
  font-size: 14px;
  font-weight: 500;
}

.comment-full-link:hover {
  color: rgba(108, 162, 17, 0.8);
  cursor: pointer;
}

.comment-user-name {
  min-height: 22px;
}

.comment-user-name a:link, .comment-user-name a:visited {
  color: #6ca211;
}

.comment-user-name a:hover {
  color: #000;
}

.comment-user-name .look-ip {
  margin: 0 4px;
  color: #444;
}

.comment-user-name .look-ip:hover {
  cursor: pointer;
}

.comment-user-name .look-ip i.active {
  color: #6ca211;
}

.comment-user-name .user-ip {
  display: none;
  color: #6ca211;
  position: absolute;
  width: 128px;
  background: white;
  border-radius: 7px;
  text-align: center;
  z-index: 2;
  box-shadow: 2px 2px 0px;
}

.comment-user-cell {
  background: #fff;
  border-radius: 0 0 10px 0;
}

.comment-shop-title a:link, .comment-shop-title a:visited {
  font-family: Arial;
  font-size: 16px;
  color: #6ca211;
  font-weight: bold;
  display: inline-block;
  padding-left: 15px;
  margin: 0;
  text-decoration: none;
  letter-spacing: 1px;
}

.comment-body {
  width: 100%;
  border-radius: 20px;
  background-color: #D5F2CD;
  padding: 23px 13px;
  text-align: left;
  margin: 0 auto;
  background: #D5F2CD;
  position: relative;
  border: 2px solid #ffffff;
  padding-bottom: 28px;
  overflow: hidden;
}

.comment-body .container {
  min-height: 80px;
}

.comment-entity .info-section {
  border-top: 1px dashed #fff;
  position: relative;
  left: 0;
  height: 20px;
  padding-top: 6px;
  margin-top: 6px;
}

.comment-entity .info-section .time {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
}

.comment-entity .comment-parent-box, .comment-entity .comment-child-box {
  /* max-width: 580px;*/
  min-height: 100px;
  margin: 20px 0;
  float: none;
}

.comment-entity .comment-child-box {
  margin-left: 38px;
  border-left: 2px dashed #B6DEC4;
}

.comment-reply {
  background: #b6dec4;
  border-radius: 55%;
  position: relative;
  top: -22px;
  border: 2px solid;
  left: 18px;
  z-index: 2;
  border-top-color: #ffffff;
  border-left-color: #ffffff;
  width: 40px;
  height: 40px;
  font-size: 20px;
  color: white;
  text-align: center;
  padding-top: 6px;
}

.comment-reply:hover {
  background-color: #D5F2CD;
  color: #8bbb9c;
  border-color: #8bbb9c;
  cursor: pointer;
}

.child-comments {
  margin-left: 20px;
  margin-top: 10px;
}

.child-comments .comment-shop-title {
  visibility: hidden;
}

.child-comments .row {
  background: #B6DEC4;
}

.child-comments .point-marker.active {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  position: absolute;
  left: -44px;
  top: 42%;
  background: #B6DEC4;
  box-shadow: 0px 0px 2px #9ec5ab;
}

.child-comments .child-comment-entity {
  position: relative;
  margin-bottom: 16px;
}

.child-comments .child-comment-entity .info-section {
  max-width: 100%;
  border-top: 1px dashed #fff;
  position: relative;
  /* bottom: -10px;*/
  left: 0;
  height: 20px;
  padding-top: 2px;
}

.child-comments .child-comment-entity .info-section .time {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
}

.child-comments .child-comment-entity:hover .point-marker {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  position: absolute;
  left: -46px;
  top: 42%;
  background: #B6DEC4;
  box-shadow: 0px 0px 2px #9ec5ab;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.child-comments .comment-body:hover {
  box-shadow: 0 0 5px #D5F2CD;
  cursor: pointer;
}

.child-comments .point-marker {
  opacity: 0;
}

ul.share-link {
  display: table;
  margin: 0;
  padding: 0;
  float: right;
  z-index: 3;
  position: relative;
}

ul.share-link a:link, ul.share-link a:visited {
  color: #6ca211;
}

ul.share-link li {
  display: table-cell;
  list-style: none;
  vertical-align: middle;
  padding: 0 5px;
  height: 38px;
  text-align: center;
}

ul.share-link .just-link-target {
  display: none;
}

.comment-selected-highlight {
  border-color: #6ca211;
  transition: all 2s;
}

.img-box {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  background: #e8f5e9;
  border: 2px solid rgba(123, 179, 31, 0.57);
  margin: 5px;
  border-radius: 10%;
  background-size: cover;
}

.img-box .img-delete {
  position: absolute;
  right: -46px;
  top: 9px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: black;
  color: #fff;
  border-radius: 8px;
}

.img-box .img-delete:hover {
  cursor: pointer;
  background: #FF0000;
}

.img-box:after {
  content: "x";
  position: absolute;
  width: 14px;
  height: 14px;
  color: #000;
  line-height: 14px;
  top: 18px;
  right: -18px;
}

.img-box .progress-bar {
  height: 10px;
  background: #7bb31f;
  background: -webkit-linear-gradient(left, #7bb31f, #ffb000);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, #7bb31f, #ffb000);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, #7bb31f, #ffb000);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #7bb31f, #ffb000);
  /* Standard syntax */
  position: absolute;
  z-index: 2;
  top: 40%;
  left: 0;
}

.attachment-list {
  margin-top: 10px;
}

.attachment-list .img-box {
  border: none;
  float: left;
}

.attachment-list .img-box:hover {
  cursor: pointer;
  border: 2px solid #fff;
}

.attachment-list .img-box:after {
  display: none;
}

.fa.cam {
  color: #b6dec4;
  font-size: 16px;
  position: absolute;
  top: 23px;
  right: 16px;
  width: 38px;
  height: 38px;
  text-align: center;
  line-height: 30px;
  z-index: 2;
  padding: 3px;
  background: #ffffff;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 27px;
}

.fa.cam:hover {
  cursor: pointer;
  color: #7bb31f;
}

.attachment-list .img-box.hovered-images {
  border: 2px solid #fff;
}

.comment-city {
  font-size: 14px;
  color: #000000;
}

.comment-city .fa {
  padding-right: 5px;
}

.shop-page {
  /* width: $shop-page-width;*/
  /*margin-top: 120px;*/
}

.shop-page h1 {
  padding: 0;
  margin: 0;
  font-size: inherit;
}

.shop-page .shop-description {
  padding: 0 15px 10px 16px;
}

.shop-page .shop-title {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  text-align: center;
  background-color: #6CA211;
  font-size: 18px;
  color: #fff;
  padding: 6px 0;
}

.shop-page .shop-rating {
  text-align: center;
}

.shop-page .shop-info .shop-detail-rating {
  padding-left: 16px;
}

.shop-page .shop-info .name-rating, .shop-page .shop-info .name-link {
  color: #000;
}

.shop-page .shop-info .number-block {
  font-weight: 800;
  color: #ffb000;
  padding: 8px 22px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  font-size: 33px;
  display: inline-block;
  margin-bottom: 10px;
}

.shop-page .shop-info .max-rate {
  color: #6ca211;
  font-size: 28px;
  font-weight: 600;
}

.shop-page .shop-info .comments-count {
  color: #6ca211;
}

.shop-page .shop-info {
  padding: 20px 20px;
  font-size: 16px;
  text-align: left;
}

.shop-page .shop-overall-rating {
  margin-top: 20px;
}

.shop-page .shop-two-box {
  overflow: hidden;
  margin: 0 auto;
  padding-top: 50px;
}

.shop-page .shop-good-caption {
  text-align: center;
  color: #77BE00;
  font-size: 18px;
  padding-bottom: 10px;
}

.shop-page .shop-bad-caption {
  text-align: center;
  color: #6F6F6F;
  font-size: 18px;
  padding-bottom: 10px;
}

.shop-page .comment-add-section h2 {
  color: #6CA211;
  font-size: 18px;
  text-align: left;
  margin-top: 60px;
}

.shop-page .bootstrap-tagsinput {
  border: 0;
  background: none;
  box-shadow: none;
}

.shop-page .bootstrap-tagsinput span {
  letter-spacing: 3px;
  font-size: 13px;
}

.shop-page .bootstrap-tagsinput input {
  background: #fff;
}

.shop-page button.new-review {
  border-radius: 10px;
  margin-top: 10px;
  min-width: 100px;
  height: 38px;
  text-transform: uppercase;
  background: white;
  border: 2px solid #b6dec4;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: bold;
  color: #555;
  box-shadow: 1px 4px 1px rgba(0, 0, 0, 0.075);
}

.shop-page button.new-review:hover {
  background: #b6dec4;
  color: #fff;
  border-color: #fff;
}

.shop-page .advantages-box {
  padding: 10px;
  /*width: 300px;*/
  min-height: 300px;
  background-color: #E0F0C4;
  border-radius: 20px;
  margin: 0 auto;
}

.shop-page .advantages-box span {
  padding: 2px 4px;
  letter-spacing: 3px;
  font-size: 13px;
  margin: 5px 5px;
  line-height: 26px;
  white-space: normal;
}

.shop-page .disadvantages-box {
  padding: 10px;
  /* width: 300px;*/
  min-height: 300px;
  background-color: #D3D3D3;
  border-radius: 20px;
  margin: 0 auto;
}

.shop-page .disadvantages-box span {
  padding: 2px 4px;
  letter-spacing: 3px;
  font-size: 13px;
  margin: 5px 5px;
  line-height: 26px;
  white-space: normal;
}

.shop-page .back-all-comment-link {
  color: #ffffff;
  font-size: 14px;
  padding: 10px;
  border-radius: 15px;
  background: #7bb31f;
  text-decoration: none;
}

.shop-page .back-all-comment-link:hover {
  background: rgba(123, 179, 31, 0.8);
  cursor: pointer;
}

.add-review-section {
  display: block;
  max-width: 800px;
  min-height: 100px;
  position: relative;
  background: #D5F2CD;
  text-align: left;
  border-radius: 20px;
  padding: 23px;
  border: 2px solid #fff;
}

.add-review-section .image-uploads input {
  width: 100%;
}

.add-review-section .typeahead-input {
  margin-bottom: 6px;
}

.add-review-section .typeahead-input, .add-review-section .typeahead-hint {
  height: 32px;
  font-size: 22px;
  width: 100%;
  background: #fff;
}

.add-review-section .typeahead-hint {
  color: #cecece;
}

.add-review-section .typeahead-results {
  width: 100%;
  padding: 5px 0px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #c1dcc3;
  box-shadow: 2px 4px 8px #c1dcc3;
}

.add-review-section .shop-selectable {
  background: #fff;
  display: block;
  font-size: 22px;
  padding: 0 10px;
}

.add-review-section .shop-selectable:hover {
  cursor: pointer;
  background: #d8f3da;
  font-weight: 600;
}

.add-review-section .checkbox label {
  font-size: 16px;
}

.add-review-section .checkbox .form-checkbox {
  box-shadow: none;
  height: 16px;
  width: 16px;
}

.add-review-section #body {
  width: 100%;
}

.add-review-section a:link, .add-review-section a:visited {
  color: #6CA211;
  border-bottom: 1px dashed;
  letter-spacing: 1px;
  font-size: 16px;
}

.add-review-section a:hover {
  text-decoration: none;
}

#preview-img-list > * {
  margin: 5px 0;
}

.S {
  transition: 1s linear all;
  -webkit-transition: 1s linear all;
}

.fade.ng-enter {
  opacity: 0;
}

.fade.ng-enter.ng-enter-active {
  opacity: 1;
}

.fade.ng-leave {
  opacity: 1;
}

.fade.ng-leave.ng-leave-active {
  opacity: 0;
}

.shop-list {
  width: 620px;
}

.shop-list .shop-parent-box {
  margin: 20px 0;
}

.shop-list .shop-box {
  width: 100%;
  min-height: 100px;
  border-radius: 20px;
  background-color: #D5F2CD;
  padding: 23px 13px;
  text-align: left;
  margin: 0 auto;
  border: 2px solid #ffffff;
}

.shop-rating-stars {
  color: #ffb000;
  font-size: 16px;
  display: inline-block;
}

.rating-section {
  margin: 20px 0;
}

.more-shops {
  position: relative;
  width: 100%;
  left: 0;
  text-align: center;
  bottom: -30px;
  display: block;
}

.more-shops a {
  text-transform: uppercase;
  display: inline-block;
  padding: 6px 30px;
  border-bottom: 1px solid #fff;
  letter-spacing: 2px;
}

.more-shops a:visited, .more-shops a:link {
  text-decoration: none;
  color: #fff;
}

.more-shops a:hover {
  color: #547e0f;
}

.list-shop-cell {
  background: #fff;
  border-radius: 10px;
  border: 2px solid;
  border-color: #e8f5e9;
  margin-top: 20px;
  padding: 0 15px;
  display: block;
}

.list-shop-cell:hover {
  border-color: #cfe4ae;
}

.list-shop-cell .rating_shop_link {
  margin: 4px 0;
  display: block;
  color: #6ca211;
}

.list-shop-cell .rating_shop_link:hover {
  color: #000;
}

.list-shop-cell .shop_name_rating {
  margin: 4px 0;
  color: #000;
}

.list-shop-cell .shop_name_rating:hover {
  color: #6ca211;
}

.shops-list-lnk {
  border-radius: 20px;
  width: 62%;
  margin: 0 auto;
  padding: 8px;
  border: 2px solid #fff;
  letter-spacing: 1px;
}

.shops-list-lnk a:visited, .shops-list-lnk a:link {
  color: #fff;
}

.shops-list-lnk a:hover {
  color: #000;
}

.article-page h1 {
  margin: 0;
}

.article-page .list-article-cell {
  background: #fff;
  border-radius: 10px;
  border: 2px solid;
  border-color: rgba(108, 162, 17, 0.37);
  margin-top: 20px;
  padding: 0 15px;
  display: block;
}

.article-page .list-article-cell:visited, .article-page .list-article-cell:link {
  text-decoration: none;
  color: #000;
}

.article-page .list-article-cell:hover {
  text-decoration: none;
  color: #000;
}

.article-page .list-article-cell:hover .name {
  text-decoration: none;
  color: #000;
}

.article-page .list-article-cell .short-body {
  margin-bottom: 20px;
  font-size: 14px;
  padding: 10px;
}

.article-page .list-article-cell .body {
  margin-bottom: 20px;
  font-size: 16px;
  padding: 10px;
}

.article-page .list-article-cell .name {
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #6ca211;
}

.article-page .list-article-cell .name a:visited, .article-page .list-article-cell .name a:link {
  color: #6ca211;
}

.article-page .list-article-cell .name a:hover {
  color: #000;
  text-decoration: none;
}

.article-page .list-article-cell .author .label, .article-page .list-article-cell .source .label, .article-page .list-article-cell .date .label {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}

.article-page .full-article-cell {
  background: #fff;
  border-radius: 10px;
  border: 2px solid;
  border-color: rgba(123, 179, 31, 0.49);
  margin: 10px 0;
  line-height: 26px;
  padding: 10px 20px;
}

.article-page .full-article-cell .body {
  margin-bottom: 20px;
  font-size: 16px;
}

.article-page .full-article-cell .name {
  font-size: 16px;
  font-weight: 600;
}

.article-page .full-article-cell .name a:visited, .article-page .full-article-cell .name a:link {
  color: #6ca211;
}

.article-page .full-article-cell .name a:hover {
  color: #000;
  text-decoration: none;
}

.article-page .full-article-cell .author .label, .article-page .full-article-cell .source .label, .article-page .full-article-cell .date .label {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.article-page a.back-link:link, .article-page a.back-link:visited {
  color: #6ca211;
  font-size: 18px;
  font-family: Roboto, Arial;
  display: inline-block;
  margin-top: 10px;
}

.article-page a.back-link:hover {
  color: #000;
}

.article-block {
  max-width: 800px;
  padding: 8px;
}

.article-block a:link, .article-block a:visited {
  width: 100%;
  display: block;
  height: 148px;
  text-decoration: none;
}

.article-block a:hover > .fa {
  color: #6ca211;
}

.article-block:hover > div {
  opacity: 0.7;
}

.article-block .fa {
  position: absolute;
  bottom: 0px;
  right: 6px;
  font-size: 28px;
  color: #e8f5e9;
  transform: rotate(-45deg);
}

.article-block > div {
  display: inline-block;
  text-align: left;
  background: #fff;
  border-radius: 20px;
  border: 6px solid;
  border-color: #e8f5e9;
  padding: 10px;
  min-height: 180px;
}

.article-block > div:hover {
  opacity: 1;
}

.article-block .short-body {
  margin-top: 10px;
  font-size: 13px;
  word-break: break-all;
  color: #000;
}

.article-block .name {
  font-size: 14px;
  font-weight: 600;
  color: #6ca211;
}

.article-block .name a:visited, .article-block .name a:link {
  color: #6ca211;
}

.article-block .name a:hover {
  color: #000;
}

.loader {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: #fff;
  opacity: 0.8;
  border-radius: 20px;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 3;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #6ca211;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Add animation to "page content" */
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s;
}

@-webkit-keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}

@keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.search-box, .search-box-general {
  background: #ffffff;
  border-radius: 20px;
  padding: 8px 0 8px 28px;
  font-size: 16px;
  border: 2px solid;
  border-color: #93bb51;
  /*width: 95%;*/
  /* max-width: 580px;*/
  /*margin: 0 auto;*/
  color: #93bb51;
}

.search-box #search-shop, .search-box #search-shop-general, .search-box-general #search-shop, .search-box-general #search-shop-general {
  display: inline-block;
  height: 32px;
  width: 80%;
  color: #000;
  vertical-align: middle !important;
}

.search-box #search-shop input, .search-box #search-shop-general input, .search-box-general #search-shop input, .search-box-general #search-shop-general input {
  width: 100%;
}

.search-box label, .search-box-general label {
  display: inline-block;
  padding-right: 12px;
}

.search-box .search-button, .search-box-general .search-button {
  padding-left: 12px;
  border: 2px solid;
  border-radius: 20px;
  padding: 6px 25px;
  background: #93bb51;
  margin-left: 12px;
  color: #fff;
}

.search-box .search-button:hover, .search-box-general .search-button:hover {
  cursor: pointer;
  border-color: #93bb51;
  background: #fff;
  color: #93bb51;
}

.search-box #search-shop, .search-box-general #search-shop {
  display: inline-block;
}

.shop-result-nothing {
  padding: 5px;
}

.tt-menu {
  color: #000;
  width: 100%;
  padding: 5px 0px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #c1dcc3;
  box-shadow: 2px 4px 3px #3e5a0d;
}

.tt-selectable {
  background: #fff;
  display: block;
  font-size: 22px;
  padding: 0 10px;
}

.tt-selectable:hover, .tt-selectable.tt-cursor {
  cursor: pointer;
  background: #d8f3da;
  font-weight: 600;
}

.twitter-typeahead {
  width: 100%;
}

.logo-sm-ru {
  display: block;
  background: url("/img/logo-sm_ru.png") no-repeat;
  width: 135px;
  height: 44px;
  margin: 8px 12px;
}

.logo-sm-en {
  display: block;
  background: url("/img/logo-sm_en.png") no-repeat;
  width: 135px;
  height: 44px;
  margin: 8px 12px;
}

.navi-stripe {
  display: table;
  width: 100%;
}

.navi-stripe > div {
  width: 70%;
  display: table-cell;
  vertical-align: middle;
}

.navi-stripe > div:first-child {
  width: 30%;
  display: table-cell;
  vertical-align: middle;
}

.mobile-icons a:link, .mobile-icons a:visited {
  color: #fff;
  font-size: 28px;
  display: inline-block;
}

.mobile-icons .active, .mobile-icons a:hover {
  color: #ffb000;
}

.mobile-icons ul {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.mobile-icons ul li {
  float: left;
  list-style: none;
  padding: 4px 9px;
  height: 45px;
}

.mobile-icons ul li.finder {
  background: #6CA211;
}

.mobile-icons ul li.finder a:link, .mobile-icons ul li.finder a:visited {
  color: #fff;
  font-size: 28px;
  display: inline-block;
}

.mobile-icons ul li.finder .active, .mobile-icons ul li.finder a:hover {
  color: #ffb000;
}

.mobile-icons .tt-menu {
  width: 220px;
}

#search-shop-mobile {
  display: inline-block;
  width: 100%;
}

#search-shop-mobile input {
  width: 100%;
  height: 37px;
  border: 0;
}

#search-shop-mobile .tt-selectable:hover {
  cursor: pointer;
}

#search-shop-mobile .shop-result-name a:visited, #search-shop-mobile .shop-result-name a:link {
  color: #000;
}

#search-shop-mobile .shop-result-name a:hover {
  text-decoration: none;
}

.mobile-search {
  background: #6CA211;
  padding: 8px 15px;
}

.mobile-search .twitter-typeahead {
  width: 100%;
}

.mobile-add-review {
  margin-top: 15px;
  max-width: 100%;
}

@media (max-width: 992px) {
  .shop-title {
    margin-top: 20px;
  }
  .content-layout {
    max-width: 100%;
  }
  .about {
    border: 0;
    box-shadow: none;
  }
  .agreement {
    border-bottom: 1px solid #fff;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  .footer {
    max-width: 620px;
  }
}

#myModal {
  text-align: center;
}

#myModal .modal-dialog {
  width: auto;
  display: inline-block;
}

#myModal .modal-dialog button.close {
  content: "x";
  position: absolute;
  background: #000;
  opacity: .6;
  z-index: 2;
  top: 0px;
  right: 0px;
  width: 38px;
  border-radius: 0 15px 0 15px;
  height: 36px;
  font-size: 23px;
  line-height: 30px;
  color: #fff;
  cursor: pointer;
}

#myModal .modal-dialog button.close:hover {
  opacity: .9;
}

#myModal .modal-content {
  overflow: hidden;
  background: transparent;
  border-radius: 15px;
}

.carousel-indicators li {
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  background-size: cover;
}

.carousel-inner {
  border-radius: 15px;
}

.carousel-inner img {
  width: 100%;
  border-radius: 15px;
}

.carousel-indicators {
  width: 100%;
  text-align: center;
  list-style: none;
  margin: 0;
  left: 0;
}

.carousel-indicators li, .carousel-indicators li.active {
  bottom: 0;
  display: inline-block;
  border: 0;
  width: auto;
  height: auto;
  margin: 0 10px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.45);
}

.carousel-indicators li:hover {
  bottom: 10px;
  position: relative;
  transition: all 300ms linear;
}

.carousel-indicators li {
  bottom: 0;
  position: relative;
  transition: all 300ms linear;
}

.carousel-indicators .img-box {
  border: none;
  margin: 0;
}

.carousel-indicators .img-box:after {
  display: none;
}

.carousel-indicators li:hover .img-box, .carousel-indicators li.active .img-box {
  border: 2px solid #fff;
}

*:focus {
  outline: 0;
}

.logo-place-td {
  height: 200px;
  vertical-align: middle;
  display: table-cell;
  width: 100%;
}

.logo-place-tr {
  display: table;
  width: 100%;
}

.navi-stripe {
  width: 100%;
  background: #6CA211;
  /* For browsers that do not support gradients */
  /*background: -webkit-linear-gradient($mainColorLight, $mainColorHover); !* For Safari 5.1 to 6.0 *!
  background: -o-linear-gradient($mainColorLight, $mainColorHover); !* For Opera 11.1 to 12.0 *!
  background: -moz-linear-gradient($mainColorLight, $mainColorHover); !* For Firefox 3.6 to 15 *!
  background: linear-gradient($mainColorLight, $mainColorHover); !* Standard syntax *!*/
}

.text-logo {
  font-size: 30px;
  color: #fff;
  margin-left: 20px;
}

tt {
  color: #ef5350;
}

body, html {
  background: #e8f5e9;
}

.left-container {
  max-width: 380px;
  margin-left: 20px;
}

.block-add {
  position: relative;
  z-index: 2;
}

.block-shop-list {
  background: #6CA211;
  border-radius: 20px;
  top: -58px;
  position: relative;
  z-index: 1;
  padding: 60px 0;
}

.inner-block {
  margin: 15px;
  background: #7bb31f;
  border-radius: 20px;
  padding: 10px 0 30px 0;
}

.big-plus {
  width: 40px;
  height: 40px;
  line-height: 35px;
  border: 3px solid #e8f5e9;
  border-radius: 50%;
  background: #6CA211;
  color: #fff;
  float: right;
  position: relative;
  left: -10px;
  font-size: 45px;
  text-align: center;
  z-index: 2;
}

.small-plus {
  width: 35px;
  height: 35px;
  line-height: 30px;
  border: 3px solid #fff;
  border-radius: 50%;
  background: #6CA211;
  color: #fff;
  float: left;
  position: relative;
  right: -16px;
  font-size: 35px;
  text-align: center;
  top: 2px;
}

.add-comment {
  display: inline-block;
  text-align: center;
  width: 183px;
  vertical-align: bottom;
  height: 38px;
  line-height: 38px;
  border-radius: 20px 0 0 20px;
  background: #6CA211;
  box-shadow: 4px 8px 24px rgba(60, 57, 57, 0.23);
}

.add-comment .add-comment-text {
  color: #100f0f;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  padding-right: 15px;
  text-align: right;
  line-height: 38px;
}

.add-comment .add-comment-text:hover {
  color: #fff;
}

.add-shop {
  display: inline-block;
  text-align: center;
  width: 200px;
  vertical-align: bottom;
  height: 38px;
  line-height: 35px;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 2px 3px 5px rgba(60, 57, 57, 0.23);
  background: #6CA211;
}

.add-shop .add-shop-text {
  color: #000;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  padding-right: 18px;
  text-align: right;
  font-family: Roboto;
  line-height: 38px;
}

.add-shop .add-shop-text:hover {
  color: #fff;
}

.fmt {
  display: inline-block;
  margin-left: 55px;
  position: relative;
  top: -4px;
}

.frt {
  line-height: 40px;
  display: inline-block;
  padding: 0;
  margin-right: 60px;
}

/**
* Shoplist column
*/
.sort-title {
  margin: 10px 0;
  text-align: center;
  color: #fff;
  font-size: 18px;
}

.shop-sort-controls {
  width: 80%;
  margin: 0 auto;
}

.shop-sort-controls > * {
  display: inline-block;
  width: 35%;
  text-align: center;
  color: #fff;
  padding: 0;
  margin: 0 10px;
  border: 2px solid #fff;
  padding: 10px 0;
  border-radius: 20px;
  letter-spacing: 1px;
  font-size: 13px;
}

.shop-sort-controls > *:hover {
  background: #6CA211;
  cursor: pointer;
}

/****** OLD MARKUP********/
.logo {
  width: 240px;
  height: 90px;
  background: url("/img/logo.png");
  background-repeat: no-repeat;
}

.comment-box {
  width: 100%;
  min-height: 100px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.comments-section {
  margin-top: 100px;
}

.time-section {
  width: 200px;
  display: inline-block;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
}

.time-text {
  font-family: Arial;
  font-size: 13px;
  color: #6CA211;
  text-align: center;
  font-weight: bold;
  padding-bottom: 5px;
  position: relative;
  z-index: 60;
  margin: 0 auto;
  padding-top: 5px;
}

.time-section-con-lup {
  display: block;
  background: url("../img/time_sector_con_lup.gif");
  height: 10px;
  width: 13px;
  padding: 0;
  margin: 0;
  float: left;
}

.time-section-con-rup {
  display: block;
  background: url("../img/time_sector_con_rup.gif");
  height: 10px;
  width: 13px;
  padding: 0;
  margin: 0;
  float: left;
}

.time-sector-center-bg {
  display: block;
  background: url("../img/time_sector_center_bg.gif");
  height: 10px;
  width: 164px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  float: left;
}

.time-sector-con-ldown {
  display: inline-block;
  background: url("../img/time_sector_con_ldown.gif");
  height: 15px;
  width: 16px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  float: left;
}

.time-sector-con-rdown {
  display: inline-block;
  background: url("../img/time_sector_con_rdown.gif");
  height: 15px;
  width: 16px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  float: right;
}

.time-sector-border-down {
  display: inline-block;
  background: url("../img/time_sector_border_down.gif") repeat-x;
  height: 4px;
  width: 132px;
  vertical-align: bottom;
  position: absolute;
  bottom: 0;
  left: 16px;
}

.time-sector-center-f {
  position: absolute;
  width: 164px;
  border: 0px solid red;
  top: 10px;
  background: #D5F2CD;
  z-index: 59;
}

.comment-author {
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
}

.comment-author-name {
  display: inline-block;
}

.comment-author-ip {
  position: absolute;
  padding: 2px;
  text-align: center;
  background: #d8d8d8;
  border-radius: 3px;
  top: -23px;
  right: 28px;
  font-size: 12px;
  border: 1px solid #BBB;
}

/*Old Markup*/
.vert_table {
  display: table;
  margin: 0 auto;
}

.vert_cell {
  display: table-cell;
}

.bold_text {
  font-family: Arial;
  font-size: 14px;
  color: #000000;
  font-weight: bold;
}

.inline_sep20px {
  width: 20px;
  display: inline-block;
}

textarea:focus, input[type="text"]:focus, input:focus, select:focus {
  border-radius: 8px;
  outline: none;
  border: 1px solid #77BE00;
  box-shadow: 0 0 5px #6CA211;
}

textarea {
  border-radius: 8px;
  padding: 10px;
  font-family: Arial;
  font-size: 16px;
  color: #000000;
  border: 1px solid #AACEAE;
}

select {
  border-radius: 8px;
  padding: 4px;
  font-family: Arial;
  font-size: 14px;
  color: #000000;
  border: 1px solid #AACEAE;
}

input[type="text"] {
  display: inline-block;
  margin-left: 0px;
  padding-left: 0px;
  position: relative;
  border-radius: 8px;
  padding: 4px;
  border: 1px solid #AACEAE;
}

input[type="checkbox"] {
  padding-bottom: 0;
  margin-bottom: 0;
}

/*FileUpload*/
.upload_success_msg {
  width: 100%;
  text-align: center;
  background: #6CA211;
  border-radius: 5px;
}

.btn {
  cursor: pointer;
  display: inline-block;
  *zoom: 1;
  *display: inline;
  position: relative;
  overflow: hidden;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 4px;
  vertical-align: middle;
}

.btn-success {
  border: 2px solid #AACEAE;
  padding: 8px 8px;
  background-color: #D7D9D4;
  background: -moz-linear-gradient(top, #FFFFFF 0%, #D7D9D4);
  background: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), to(#D7D9D4));
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
}

.btn-success:hover {
  border: 2px solid #77BE00;
}

.btn-success.btn-small[aria-disabled],
.btn-primary[disabled],
.btn-warning[disabled] {
  opacity: .7;
}

.btn-primary {
  color: #333;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 8px 20px;
  background-color: #62c462;
  background: -moz-linear-gradient(top, #62c462 0%, #51a351);
  background: -webkit-gradient(linear, left top, left bottom, from(#62c462), to(#51a351));
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
}

.btn-warning {
  color: #333;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 8px 20px;
  background-color: #bd362f;
  background: -moz-linear-gradient(top, #ee5f5b 0%, #bd362f);
  background: -webkit-gradient(linear, left top, left bottom, from(#ee5f5b), to(#bd362f));
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
}

.btn-small {
  padding: 5px 10px;
  font-size: 16px;
}

.btn input {
  top: -10px;
  right: -40px;
  z-index: 2;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 50px;
  cursor: pointer;
}

.btn-txt {
  position: relative;
}

.btn .progress {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .5;
  position: absolute;
}

.progress .bar {
  width: 0;
  top: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  background-color: #f60;
}

.progress-small {
  height: 5px;
  padding: 1px;
  box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

.progress-small .bar {
  width: 0;
  height: 100%;
  position: static;
  border-radius: 10px;
  background-color: orange;
}

.webcam,
.userpic {
  width: 200px;
  height: 200px;
  border: 2px solid #aaa;
  display: inline-block;
  position: relative;
  background: url("uploader/userpic.gif") no-repeat;
  background-size: cover;
}

.webcam .btn,
.userpic .btn {
  margin-top: 150px;
}

.webcam__preview,
.userpic__preview {
  position: absolute;
}

.b-upload {
  white-space: nowrap;
}

.b-upload__name,
.b-upload__size {
  display: inline-block;
  position: relative;
  overflow: hidden;
  max-width: 150px;
  vertical-align: middle;
}

.b-upload__size {
  color: #666;
  font-size: 12px;
}

.b-upload .js-files:after {
  clear: both;
  content: '';
  display: block;
}

.b-upload__dnd {
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

.b-upload__dnd_hover {
  color: #fff;
  background-color: orange;
}

.b-upload__hint {
  padding: 5px 8px;
  margin: 5px 0 5px 0;
  font-size: 12px;
  white-space: normal;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.08);
}

.b-thumb {
  float: left;
  margin: 3px;
  padding: 5px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  background-color: #fff;
  border: 1px solid #AACEAE;
  border-radius: 8px;
}

.b-thumb__del {
  top: 2px;
  right: 4px;
  color: #FF0000;
  cursor: pointer;
  opacity: 0;
  z-index: 999;
  position: absolute;
  font-size: 20px;
  -webkit-transition: opacity .1s ease-in;
  -moz-transition: opacity .1s ease-in;
  transition: opacity .1s ease-in;
}

.b-thumb:hover .b-thumb__del {
  opacity: 1;
}

.b-thumb__rotate {
  top: 40%;
  left: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin: -16px 0 0 -16px;
  position: absolute;
  background: url("/uploader/rotate.png");
}

.b-thumb__preview {
  width: 80px;
  height: 80px;
  -webkit-transition: -webkit-transform .2s ease-in;
  -moz-transition: -moz-transform .2s ease-in;
  transition: transform .2s ease-in;
  text-align: center;
}

.b-thumb__preview__pic {
  width: 10%;
  height: 10%;
  background: url("uploader/file-icon.png") 50% 50% no-repeat;
}

.b-thumb__name {
  width: 80px;
  overflow: hidden;
  font-size: 12px;
  text-align: center;
}

.b-thumb__progress {
  top: 75px;
  left: 10px;
  right: 10px;
  position: absolute;
}

/*Add New Shop*/
.add_shop_content {
  text-align: left;
}

.add_shop_title {
  font-family: Arial;
  font-size: 14px;
  color: #000000;
  font-weight: bold;
}

.add_shop_restriction {
  margin: 0 auto;
  width: 580px;
  font-family: Arial;
  font-size: 14px;
  color: #000000;
  display: block;
  text-align: justify;
  padding: 10px;
  border: 1px solid #AACEAE;
  border-radius: 8px;
}

/*Form checker style*/
.jquery_form_help_ {
  font-family: Arial;
  font-size: 14px;
  color: #6CA211;
  display: block;
}

.form-error {
  font-family: Arial;
  font-size: 14px;
  color: #A80000;
  background: #FFE5ED;
  border: 1px solid #EED3D7;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 5px;
}

/*Bread_crumbs*/
.bread_crum_text, .bread_crum_text a:link, .bread_crum_text a:visited {
  font-family: Arial;
  font-size: 14px;
  color: #6CA211;
  text-decoration: none;
}

.bread_crum_text a:hover {
  text-decoration: underline;
  color: #000000;
}

.icon_home {
  padding-right: 5px;
  display: inline-block;
  background: url("../img/icon_home.gif") no-repeat;
  height: 24px;
  width: 24px;
}

.bread_crum_sep {
  font-size: 14px;
  color: #6CA211;
}

/*Info Base*/
.inf_base_content {
  font-size: 14px;
  color: #000000;
  text-align: justify;
}

.inf_base_block_last_txt {
  display: inline-block;
  font-size: 13px;
  color: #000000;
}

.inf_base_block_heading {
  font-family: Arial;
  font-size: 16px;
  color: #000000;
  font-weight: bold;
}

.inf_base_block_last {
  font-family: Arial;
  font-size: 13px;
  color: #6CA211;
  padding-left: 10px;
  padding-top: 10px;
  font-weight: bold;
  display: inline-block;
  padding-right: 5px;
}

.inf_base_block_descr {
  font-family: Arial;
  font-size: 13px;
  color: #000000;
  padding-left: 15px;
  padding-top: 10px;
  padding-right: 10px;
}

.inf_base_block_title {
  font-family: Arial;
  font-size: 16px;
  color: #000000;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
}

.inf_base_block {
  border: 1px solid #6CA211;
  width: 620px;
  border-radius: 10px;
  text-align: left;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.inf_base_block:hover {
  background: #D5F2CD;
}

.inf_base_table {
  text-align: left;
}

/*Common Properties*/
.input_line {
  padding: 0;
  margin: 0;
  display: table-cell;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
}

.form_title {
  font-family: Arial;
  font-size: 13px;
  color: #000000;
  padding-left: 2px;
  padding-top: 2px;
}

.mhd {
  color: #000000;
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
  padding-top: 2px !important;
  padding-bottom: 3px !important;
}

.inline {
  display: inline-block;
}

/*Shop Page*/
.shop_benefits_string {
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.shop_benefits_txt {
  font-family: Arial;
  font-size: 13px;
  color: #000000;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  padding-right: 10px;
  width: 260px;
}

.shop_weakness_string {
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.shop_weakness_txt {
  font-family: Arial;
  font-size: 13px;
  color: #000000;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  padding-right: 10px;
  width: 260px;
}

.inline1s {
  display: table-cell;
  width: 45px;
  height: 30px;
  text-decoration: none;
}

a .shop_benefits_num:hover {
  border: 1px solid grey;
  display: table-cell;
  border-radius: 5px;
  text-decoration: none;
}

a .shop_benefits_num, a .shop_benefits_num:visited, a .shop_benefits_num:link {
  border: 1px solid #ffffff;
  border-radius: 5px;
  background: #ffffff;
  width: 45px;
  height: 30px;
  margin: 0 auto;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
  font-family: Arial;
  font-size: 13px;
  color: #000000;
  text-decoration: none;
}

a .shop_weakness_num:hover {
  border: 1px solid grey;
  display: table-cell;
  border-radius: 5px;
  text-decoration: none;
}

a .shop_weakness_num, a .shop_weakness_num:visited, a .shop_weakness_num:link {
  border: 1px solid #ffffff;
  border-radius: 5px;
  background: #ffffff;
  width: 45px;
  height: 30px;
  margin: 0 auto;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
  font-family: Arial;
  font-size: 13px;
  color: #000000;
  text-decoration: none;
}

.coment_sector_title {
  font-family: Arial;
  font-size: 18px;
  color: #000000;
}

.shop_full_raiting_numstat {
  display: inline-block;
  vertical-align: middle;
}

/* +Rate datail description table+ */
.shop_detail_rate {
  display: block;
  border: 1px solid #6CA211;
  border-radius: 15px;
  width: 400px;
  margin: 0 auto;
  font-family: Arial;
  font-size: 13px;
  color: #000000;
}

.shop_detail_table {
  display: table;
  width: 400px;
  text-align: left;
  padding: 0;
  margin: 0;
}

.shop_detail_rate_row {
  display: block;
  vertical-align: middle;
  border-bottom: 1px solid #6CA211;
  padding: 0;
  margin: 0;
}

.shop_detail_rate_row_l {
  display: block;
  vertical-align: middle;
  border-bottom: 0px solid #6CA211;
  padding: 0;
  margin: 0;
}

.shop_detail_rate_txt {
  vertical-align: middle;
  display: inline-block;
  width: 130px;
  text-align: left;
  padding: 5px 0px 5px 10px;
}

.shop_detail_rate_inf {
  vertical-align: middle;
  display: inline-block;
  width: 259px;
  text-align: left;
  border-left: 1px solid #6CA211;
  padding: 0;
  margin: 0;
}

.shop_detail_rate_inf_txt {
  display: inline-block;
  padding: 5px 0px 5px 10px;
  margin: 0;
}

.shop_h_line_g1 {
  width: 100%;
  display: block;
  border-top: 1px solid #6CA211;
}

.shop_detail_cllick {
  font-family: Arial;
  font-size: 14px;
  color: #000000;
  padding-bottom: 10px;
}

/* -Rate datail description table- */
.rate_text {
  display: inline;
  vertical-align: middle;
  font-family: Arial;
  font-size: 14px;
  color: #000000;
  padding: 0;
  margin: 0;
}

.shop_full_raiting {
  display: table;
}

.shop_full_raiting_cell {
  display: table-cell;
  height: 37;
}

.shop_num_rait {
  display: inline-block;
  vertical-align: middle;
  font-family: Arial;
  font-size: 18px;
  color: #000000;
}

.shop_num_rait #value5 {
  font-family: Arial;
  font-size: 18px;
  color: #000000;
  font-weight: bold;
}

.shop_weak_benef_grid {
  position: relative;
  display: block;
}

.shop_benefits_sector {
  width: 300px;
  position: relative;
  display: inline-block;
  /*border:1px solid red;*/
  min-height: 300px;
  background: #E0F0C4;
  vertical-align: top;
  margin-right: 20px;
}

.shop_weakness_sector {
  width: 300px;
  position: relative;
  display: inline-block;
  min-height: 300px;
  background: #D3D3D3;
  vertical-align: top;
}

.shop_benefits_title {
  color: #77BE00;
  display: inline-block;
  text-align: center;
  top: -30px;
  margin: 0 auto;
  position: relative;
  width: 200px;
  margin-right: 20px;
  font-family: Arial;
  font-size: 18px;
}

.shop_weakness_title {
  color: #6F6F6F;
  display: inline-block;
  text-align: center;
  top: -30px;
  margin: 0 auto;
  position: relative;
  width: 200px;
  margin-right: 20px;
  font-family: Arial;
  font-size: 18px;
}

.shop_benef_left_up {
  position: absolute;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("../img/shop_ben_con_up_l.png") no-repeat;
  left: 0;
  top: 0;
}

.shop_benef_right_d {
  position: absolute;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("../img/shop_ben_con_d_r.png") no-repeat;
  right: 0;
  bottom: 0;
}

.shop_benef_right_up {
  position: absolute;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("../img/shop_ben_con_up_r.png") no-repeat;
  right: 0;
  top: 0;
}

.shop_weak_right_up {
  position: absolute;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("../img/shop_weak_con_up_r.png") no-repeat;
  top: 0;
  right: 0;
}

.shop_weak_left_up {
  position: absolute;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("../img/shop_weak_con_up_l.png") no-repeat;
  top: 0;
  left: 0;
}

.shop_weak_right_d {
  position: absolute;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("../img/shop_weak_con_d_r.png") no-repeat;
  bottom: 0;
  right: 0;
}

.shop_benef_left_d {
  position: absolute;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("../img/shop_ben_con_d_l.png") no-repeat;
  bottom: 0;
  left: 0;
}

.shop_weak_left_d {
  position: absolute;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("../img/shop_weak_con_d_l.png") no-repeat;
  bottom: 0;
  left: 0;
}

.title_shop_sector_l {
  display: inline-block;
  width: 20px;
  height: 50px;
  background: url("../img/shop_con_up_l.png") no-repeat;
  float: left;
}

.title_shop_sector_r {
  display: inline-block;
  width: 20px;
  height: 50px;
  background: url("../img/shop_con_up_r.png") no-repeat;
  float: right;
}

.title_shop_sector {
  width: 700px;
  height: 50px;
  background: #6CA211;
  display: table;
}

.title_shop_txt {
  font-family: Arial;
  font-size: 18px;
  color: #ffffff;
  display: table-cell;
  vertical-align: middle;
}

.shop_link_title {
  display: inline-block;
  font-family: Arial;
  font-size: 13px;
  color: #000000;
  font-weight: bold;
  padding-right: 5px;
}

.shop_link_title_content, .shop_link_title_content a:link, .shop_link_title_content a:visited {
  display: inline-block;
  font-family: Arial;
  font-size: 13px;
  color: #0000F0;
  text-decoration: none;
}

.shop_link_title_content a:hover {
  display: inline-block;
  font-family: Arial;
  font-size: 13px;
  text-decoration: underline;
}

.position_left {
  padding-left: 20px;
  text-align: left;
  position: relative;
}

.position_center {
  text-align: center;
  position: relative;
}

.shop_full_raiting_title {
  display: inline-block;
  font-family: Arial;
  font-size: 13px;
  color: #000000;
  font-weight: bold;
  padding-right: 5px;
  vertical-align: middle;
}

.shop_full_raiting_content {
  display: inline-block;
  vertical-align: middle;
}

/*Shop Page*/
.mainframe {
  width: 1230px;
  height: 100%;
  margin: 0 auto;
}

a.page_next:link, a.page_next:visited {
  width: 28px;
  height: 28px;
  display: table-cell;
  vertical-align: middle;
  font-family: Arial;
  font-size: 18px;
  color: #6CA211;
  text-decoration: none;
  padding-left: 5px;
}

a.page_next:hover {
  color: #000000;
}

.page_current {
  font-size: 18px;
  color: #000000;
  text-decoration: none;
}

.page_circle, .page_circle a:link, .page_circle a:visited {
  width: 28px;
  height: 28px;
  background: url("../img/page_circle_green.png") no-repeat;
  display: table-cell;
  vertical-align: middle;
  font-family: Arial;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
}

.page_circle a:hover {
  color: #000000;
  text-decoration: none;
}

.page_circle_no {
  width: 28px;
  height: 28px;
  background: none;
  display: table-cell;
  vertical-align: middle;
}

#weakness_t2, #benefits_t2 {
  display: inline-block;
}

.adcom_full_raiting_content {
  display: inline-block;
  vertical-align: middle;
}

.adcom_shop_nums {
  padding-left: 5px;
  display: table-cell;
  font-family: Arial;
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  height: 100%;
  vertical-align: bottom;
}

.adcom_shop_nums_r {
  padding-right: 2px;
  font-weight: normal;
  display: inline;
  font-family: Arial;
  font-size: 18px;
  color: #000000;
}

.adcom_shop_exam_section {
  padding: 5px 0px 5px 0px;
  display: table;
}

.adcom_shop_exam_section_cell {
  display: table-cell;
  height: 37;
}

.first_section {
  display: inline-block;
  width: 340px;
  height: 100%;
  padding: 0 10px 0 0;
  margin: 0;
}

.second_section {
  display: inline-block;
  width: 745px;
  height: 100%;
  padding: 0 10px 0 0;
  margin: 0;
  padding: 0;
  vertical-align: top;
}

.third_section {
  display: inline-block;
  width: 125px;
  height: 100%;
  margin: 0;
  padding: 0;
}

.logo {
  width: 273px;
  height: 110px;
  background: url("../img/upshop_logo.jpg") no-repeat;
  display: block;
}

.top_line_sector1 {
  width: 332px;
  display: table;
  text-align: right;
}

.top_line_sector2 {
  width: 332px;
  display: block;
  line-height: 34px;
}

.add_commet {
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.add_comment_text, .add_comment_text:link, .add_comment_text:visited {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  font-family: Arial;
  color: #000000;
  text-decoration: none;
}

.frt {
  display: inline-block;
  padding: 0;
  margin-right: 60px;
}

.frt:hover > .add_comment_plus {
  background: url("../img/add_comm_plus_a.gif") no-repeat;
}

.frt:hover > .add_comment_center .add_comment_text {
  color: #FFFFFF;
}

/*.add_comment_text a:hover{
padding-top:8px;
font-size:16px;
font-weight:bold;
font-family:Arial;
color:#FFFFFF;
text-decoration:none;
}*/
.add_comment_center {
  display: inline-block;
  text-align: center;
  width: 153px;
  background: url("../img/add_comm_bg.gif") repeat-x;
  vertical-align: bottom;
  height: 38px;
}

.add_comment_plus {
  display: inline-block;
  background: url("../img/add_comm_plus.gif") repeat-x;
  width: 47px;
  height: 40px;
  vertical-align: bottom;
}

.add_comm_frist {
  display: inline-block;
  background: url("../img/add_comm_frist.gif") no-repeat;
  width: 13px;
  height: 38px;
  vertical-align: bottom;
}

/*a .add_comment_plus:hover{
display:inline-block;
background: url('../img/add_comm_plus_a.gif') repeat-x;
width:47px;
height:40px;
}*/
.after_add_commet_top {
  background: url("../img/after_add_commet_top.gif") no-repeat;
  width: 42px;
  height: 40px;
  vertical-align: bottom;
  display: inline-block;
}

.before_add_shop {
  display: inline-block;
  background: url("../img/before_add_shop.gif") no-repeat;
  width: 59px;
  height: 34px;
  vertical-align: bottom;
}

a .add_shop_plus, a .add_shop_plus:link, a .add_shop_plus:visited {
  display: inline-block;
  background: url("../img/add_shop_circle.gif") no-repeat;
  width: 37px;
  height: 34px;
  vertical-align: bottom;
}

.fmt:hover > .add_shop_plus {
  background: url("../img/add_shop_circle_a.gif") no-repeat;
}

.fmt:hover > .add_shop .add_shop_text {
  color: #ffffff;
}

/*a .add_shop_plus:hover{
display:inline-block;
background: url('../img/add_shop_circle_a.gif') no-repeat;
width:37px;
height:34px;
}
*/
a .add_shop, a .add_shop:link, a .add_shop:visited {
  width: 147px;
  display: inline-block;
  background: #6CA211;
  vertical-align: bottom;
  height: 34px;
}

.add_shop_circle_end {
  display: inline-block;
  background: url("../img/add_shop_circle_end.gif") no-repeat;
  height: 34px;
  width: 17px;
  vertical-align: bottom;
}

.after_add_shop {
  display: inline-block;
  background: url("../img/after_add_shop.gif") no-repeat;
  height: 34px;
  width: 72px;
  vertical-align: bottom;
}

a .add_shop_text, a .add_shop_text:link, a .add_shop_text :visited {
  width: 147px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  font-size: 14px;
  /*font-weight:bold;*/
  color: #000000;
  font-family: Arial;
  text-decoration: none;
  background: #6CA211;
}

/*
a .add_shop_text:hover{
width:147px;
display:inline-block;
vertical-align:top;
padding-top:8px;
text-align:center;
font-size:14px;
font-weight:bold;
color:#ffffff;
font-family:Arial;
text-decoration:none;

}*/
.inline_block {
  display: inline-block;
}

.top_line_sector3 {
  width: 332px;
  background: #6CA211;
  text-align: center;
}

.top_line_sector4 {
  width: 332px;
  position: relative;
  height: 66px;
}

.left_bottom_con {
  position: absolute;
  left: 0;
  bottom: 0;
  display: inline-block;
  height: 19px;
}

.right_bottom_con {
  position: absolute;
  right: 0;
  bottom: 0;
  display: inline-block;
  height: 19px;
}

.inner_frame_box {
  display: block;
}

.inner_top_sector {
  width: 322px;
  margin: 0 auto;
  display: block;
  position: relative;
  font-size: 0px;
}

.inner_corner_left {
  position: relative;
  width: 23px;
  height: 23px;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.inner_corner_right {
  position: relative;
  width: 23px;
  height: 23px;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.inner_center {
  position: relative;
  width: 276px;
  height: 23px;
  display: inline-block;
  margin: 0;
  padding: 0;
  background: url("../img/inframe_center.gif");
}

.inner_middle_sector {
  position: relative;
  margin: 0 auto;
  display: block;
  border-left: 1px solid #FFFFFF;
  border-right: 1px solid #FFFFFF;
  width: 320px;
  height: 400px;
  background: #77BE00;
  text-align: left;
}

.inner_bottom_sector {
  width: 322px;
  margin: 0 auto;
  display: block;
  position: relative;
  font-size: 0px;
}

.inner_corner_bottom_left {
  position: relative;
  width: 23px;
  height: 23px;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.inner_corner_bottom_right {
  position: relative;
  width: 23px;
  height: 23px;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.inner_center_bottom {
  position: relative;
  width: 276px;
  height: 23px;
  display: inline-block;
  margin: 0;
  padding: 0;
  background: url("../img/inframe_center_bottom.gif");
}

.sort_title {
  font-family: Arial;
  font-size: 16px;
  color: #ffffff;
}

.sort_con_left {
  display: inline-block;
  background: url("../img/sort_con_left.gif");
  width: 18px;
  height: 36px;
}

.sort_con_right {
  display: inline-block;
  background: url("../img/sort_con_right.gif");
  width: 18px;
  height: 36px;
}

.sort_center {
  display: inline-block;
  width: 70px;
  height: 32px;
  background-color: #77BE00;
  border-top: 2px solid #FFFFFF;
  border-bottom: 2px solid #FFFFFF;
  vertical-align: bottom;
}

.sort_center_content {
  display: table-cell;
  width: 70px;
  height: 32px;
  vertical-align: middle;
}

.rts:hover > .sort_rows {
  background: url("../img/sort_rows_a.gif") no-repeat;
  color: #000000;
}

.rts:hover > div {
  color: #000000;
}

.sort_rows, .sort_rows:link, .sort_rows:visited {
  width: 14px;
  height: 15px;
  display: inline-block;
  margin-left: 2px;
  background: url("../img/sort_rows.gif") no-repeat;
  vertical-align: middle;
}

/*.sort_rows:hover {
width:14px;
height:15px;
display:inline-block;
vertical-align: middle;
margin-left:2px;
background: url('../img/sort_rows_a.gif') no-repeat;
}
*/
a .sort_order_assessment, a .sort_order_assessment:link, a .sort_order_assessment:visited {
  font-family: Arial;
  font-size: 14px;
  color: #FFFFFF;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
}

/*.sort_order_assessment:hover {
font-family:Arial;
font-size:14px;
color:#000000;
display:inline-block;
vertical-align: middle;
text-decoration:none;
}
*/
a .sort_order_comment, a .sort_order_comment:link, a .sort_order_comment:visited {
  vertical-align: middle;
  display: inline-block;
  font-family: Arial;
  font-size: 14px;
  color: #FFFFFF;
  text-decoration: none;
}

/*a .sort_order_comment:hover{
vertical-align: middle;
display:inline-block;
font-family:Arial;
font-size:14px;
color:#000000;
text-decoration:none;
}*/
.sort_box1 {
  display: inline-block;
  margin: 0 auto;
  width: 106px;
  position: relative;
  padding-right: 10px;
}

.sort_box2 {
  display: inline-block;
  margin: 0 auto;
  width: 106px;
  position: relative;
}

.sort_container {
  position: absolute;
  z-index: 90;
  top: -15px;
  width: 322px;
}

.sort_container_rel {
  position: relative;
  width: 322px;
  text-align: center;
}

.shop_rating_box {
  margin: 0 auto;
  display: block;
  padding-left: 25px;
  padding-top: 10px;
  vertical-align: top;
  position: relative;
}

.stars_rating {
  display: inline-block;
}

.num_rating {
  padding-right: 5px;
  display: inline-block;
  font-family: Arial;
  font-size: 14px;
  color: #000000;
  font-weight: bold;
}

.shop_name_rating, .shop_name_rating a:link, .shop_name_rating a:visited {
  font-family: Arial;
  font-size: 16px;
  color: #000000;
  font-weight: bold;
  display: inline-block;
  padding: 0;
  margin: 0;
  text-decoration: none;
  letter-spacing: 1px;
}

.rating_go_arrow {
  display: inline-block;
  background: url("../img/rating_arrow_box.gif") no-repeat;
  width: 12px;
  height: 12px;
  margin-left: 5px;
  padding: 0px;
}

.shop_name_rating a:hover .rating_go_arrow {
  display: inline-block;
  background: url("../img/rating_arrow_box.gif") no-repeat;
  width: 12px;
  height: 12px;
  padding: 0px;
}

.shop_name_rating a:hover {
  font-family: Arial;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  display: inline-block;
  margin: 0;
  text-decoration: none;
}

.rating_benefits {
  clear: right;
  font-family: Arial;
  font-size: 13px;
  color: #006600;
  display: block;
  padding: 0;
  margin: 0;
}

.rating_disadvantage {
  clear: right;
  font-family: Arial;
  font-size: 13px;
  color: #6E6767;
  display: block;
  padding: 0;
  margin: 0;
}

.rating_comments {
  font-family: Arial;
  font-size: 13px;
  color: #000;
  display: block;
  padding: 0;
  margin: 0;
}

.rating_benefits_quant {
  clear: right;
  font-family: Arial;
  font-size: 13px;
  color: #006600;
  display: block;
  padding: 0;
  margin: 0;
}

.rating_disadvantage_quant {
  clear: right;
  font-family: Arial;
  font-size: 13px;
  color: #6E6767;
  display: block;
  padding: 0;
  margin: 0;
}

.rating_comments_quant {
  clear: right;
  font-family: Arial;
  font-size: 12px;
  color: #FFFFFF;
  display: block;
  padding: 0px;
  margin: 0;
}

.rating_plus {
  width: 12px;
  height: 12px;
  background: url("../img/plus_rating.png");
  display: inline-block;
  padding: 0;
  font-family: Arial;
  font-size: 13px;
  vertical-align: middle;
}

.rating_minus {
  width: 12px;
  height: 12px;
  background: url("../img/minus_rating.png");
  display: inline-block;
  padding: 0;
  font-family: Arial;
  font-size: 13px;
  vertical-align: middle;
}

.rating_comments_icon {
  width: 16px;
  height: 15px;
  color: #FFFFFF;
  display: inline-block;
  padding: 0;
  margin: 0;
  line-height: 15px;
  vertical-align: middle;
}

.rating_shop_link, .rating_shop_link a:link, .rating_shop_link a:visited {
  display: inline;
  font-family: Arial;
  font-size: 12px;
  color: #E8F5E9;
  padding: 0;
  margin: 0;
  text-decoration: none;
}

.rating_shop_link a:hover {
  display: inline;
  font-family: Arial;
  font-size: 12px;
  color: #000000;
  padding: 0;
  margin: 0;
  text-decoration: none;
}

.clear_area {
  clear: both;
  font-size: 0px;
  padding: 0;
  margin: 0;
}

.column_1 {
  display: block;
  float: left;
  line-height: 110%;
  padding-right: 10px;
}

.column_2 {
  display: block;
  float: left;
  line-height: 110%;
  padding-right: 10px;
}

.column_3 {
  float: left;
  line-height: 110%;
  padding-right: 10px;
}

.pad_line {
  height: 17px;
  display: table-cell;
  vertical-align: middle;
}

.info_base_box {
  position: relative;
}

.info_box_center_up {
  display: block;
  border-top: 2px solid #77BE00;
  height: 11px;
  width: 206px;
}

.info_box_con_l_up {
  background: url("../img/news_left_up_con.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
}

.info_box_con_r_up {
  background: url("../img/news_right_up_con.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
}

.info_box_center_down {
  display: block;
  border-bottom: 2px solid #77BE00;
  height: 11px;
  width: 206px;
}

.info_box_con_l_down {
  background: url("../img/news_left_down_con.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
}

.info_box_con_r_down {
  background: url("../img/news_right_down_con.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
}

.info_box_left_border {
  display: block;
  border-left: 2px solid #77BE00;
  width: 11px;
  height: 150px;
}

.info_box_right_border {
  display: block;
  border-right: 2px solid #77BE00;
  width: 11px;
  height: 150px;
}

.info_box_center {
  display: block;
  width: 206px;
  height: 150px;
}

.info_box_col_left, .info_box_col_center, .info_box_col_right {
  display: inline-block;
  padding: 0;
  margin: 0;
  vertical-align: top;
  font-family: Arial;
  font-size: 13px;
  color: #000000;
  text-align: justify;
}

.info_box_img_left {
  background: url("../img/con_left_over.png");
  height: 13px;
  width: 13px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}

.info_box_img_right {
  background: url("../img/con_right_over.png");
  height: 13px;
  width: 13px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
}

.info_box_img {
  width: 206px;
}

h2 {
  padding: 0;
  font-size: none;
  font-family: none;
  margin: 0;
  font-weight: normal;
}

.heading_sector_text {
  font-family: Arial;
  font-size: 16px;
  color: #000000;
  font-weight: bold;
}

.empty_box_150h {
  width: 10px;
  height: 150px;
}

.empty_box_100h {
  width: 10px;
  height: 100px;
}

.empty_box_50h {
  width: 10px;
  height: 50px;
}

.empty_box_20h {
  width: 10px;
  height: 20px;
}

.central_content {
  text-align: center;
}

.pagination_top {
  font-family: Arial;
  font-size: 14px;
  color: #000000;
  font-weight: normal;
  margin: 0 auto;
  text-align: center;
  display: table;
}

.comment_section_district {
  display: block;
  width: 620px;
  min-height: 100px;
  position: relative;
  background: #D5F2CD;
  text-align: left;
  margin: 0 auto;
}

.comment_section_content {
  display: inline-block;
  width: 580px;
  min-height: 100px;
  margin: 23px 13px 13px 13px;
  font-family: Arial;
  font-size: 14px;
  color: #000000;
  position: relative;
  background: #D5F2CD;
  /*border:2px solid red;*/
}

.comment_section_center_up {
  display: block;
  border-top: 2px solid #D5F2CD;
  height: 11px;
  width: 594px;
}

.comment_section_con_l_up {
  background: url("../img/lightgreen_up_l.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 22;
}

.comment_section_con_r_up {
  background: url("../img/lightgreen_up_r.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 22;
}

.comment_section_center_down {
  display: block;
  border-bottom: 2px solid #D5F2CD;
  height: 11px;
  width: 594px;
}

.comment_section_con_l_down {
  background: #D5F2CD;
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
  /*background:url('../img/lightgreen_d_l.gif');*/
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 22;
}

.comment_section_con_r_down {
  background: url("../img/lightgreen_d_r.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 22;
}

.comment_section_left_border {
  display: block;
  border-left: 2px solid #D5F2CD;
  width: 11px;
  min-height: 100px;
}

.comment_section_right_border {
  display: block;
  border-right: 2px solid #D5F2CD;
  width: 11px;
  min-height: 100px;
}

.comment_section_center {
  display: block;
  width: 580px;
  min-height: 100px;
}

.comment_section_col_left, .comment_section_col_center, .comment_section_col_right {
  display: inline-block;
  padding: 0;
  margin: 0;
  vertical-align: top;
  font-family: Arial;
  font-size: 13px;
  background: #D5F2CD;
  text-align: justify;
}

.comment_section_top {
  width: 620px;
  text-align: left;
  margin: 0 auto;
}

.comment_section_bottom {
  width: 620px;
  height: 25px;
  margin: 0 auto;
  text-align: left;
  padding: 0;
  border: 0;
  vertical-align: top;
}

.comment_section_replay {
  width: 200px;
  display: inline-block;
  background: #FFFFFF;
  height: 30px;
}

.comm_sec_con_l {
  display: inline-block;
  width: 21px;
  height: 25px;
  background: url("../img/main_com_repl_con_l.gif");
  padding: 0;
  margin: 0;
  vertical-align: top;
}

.comm_sec_con_r {
  display: inline-block;
  width: 21px;
  height: 25px;
  background: url("../img/main_com_repl_con_r.gif");
  padding: 0;
  margin: 0;
  vertical-align: top;
}

.comment_section_reply_txt, .comment_section_reply_txt a:link, .comment_section_reply_txt a:visited {
  display: inline-block;
  width: 158px;
  height: 25px;
  background: #D5F2CD;
  color: #6CA211;
  font-family: Arial;
  font-size: 16px;
  padding: 0;
  margin: 0;
  vertical-align: top;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
}

.comment_section_reply_txt a:hover {
  display: inline-block;
  color: #000000;
  text-decoration: underline;
}

.time_text {
  font-family: Arial;
  font-size: 14px;
  color: #6CA211;
  text-align: center;
  font-weight: bold;
  padding-bottom: 5px;
  position: relative;
  z-index: 60;
  margin: 0 auto;
  padding-top: 5px;
}

.cell_section_v {
  display: table-cell;
  height: 100%;
}

span.author_section {
  display: inline-block;
  font-family: Arial;
  font-size: 10px;
  width: 189px;
  text-align: left;
  padding: 0;
  margin: 0;
  padding-left: 13px;
  vertical-align: bottom;
}

span.shop_section, span.shop_section a:link, span.shop_section a:visited {
  /*height:26px;*/
  display: inline-block;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  width: 215px;
  text-align: right;
  padding-bottom: 3px;
  padding-right: 13px;
  color: #6CA211;
  text-decoration: none;
  margin: 0px;
  vertical-align: bottom;
}

span.shop_section a:hover {
  display: inline-block;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  width: 228px;
  text-align: right;
  color: #000000;
  text-decoration: underline;
  margin: 0px;
  vertical-align: bottom;
}

span.shop_section a:hover > div {
  background: url("../img/i_a.png") no-repeat;
}

/*Section for Vote */
.comment_section_vote {
  width: 410px;
  text-align: right;
  display: inline-block;
  padding: 0 10px 0 0;
  margin: 0;
  border: 0;
  vertical-align: top;
}

/*Is this comment was helpful?*/
.comment_section_vote_desc {
  display: inline-block;
  font-family: Arial;
  font-size: 13px;
  padding-right: 10px;
  font-weight: bold;
}

.comment_section_vote_yes, .comment_section_vote_yes a:link, .comment_section_vote_yes a:visited {
  display: inline-block;
  font-family: Arial;
  font-size: 13px;
  padding-right: 2px;
  font-weight: bold;
  color: #009900;
  text-decoration: none;
}

.comment_section_vote_yes a:hover {
  text-decoration: underline;
  color: #000000;
}

.comment_section_vote_yes_quant {
  display: inline-block;
  font-family: Arial;
  font-size: 13px;
  color: #009900;
}

.comment_section_vote_sep {
  display: inline-block;
  font-family: Arial;
  font-size: 13px;
  padding: 5px;
}

.comment_section_vote_no_quant {
  display: inline-block;
  font-family: Arial;
  font-size: 13px;
  color: #FF0000;
}

.comment_section_vote_no, .comment_section_vote_no a:link, .comment_section_vote_no a:visited {
  display: inline-block;
  font-family: Arial;
  font-size: 13px;
  padding-right: 2px;
  font-weight: bold;
  color: #FF0000;
  text-decoration: none;
}

.comment_section_vote_no a:hover {
  text-decoration: underline;
  color: #000000;
}

.dotted_line_reply {
  height: 50px;
  width: 3px;
  background: url(../img/dotted_reply_line.gif) repeat-y;
  margin: 0 auto;
  padding-right: 440px;
}

.dotted_line_reply2 {
  display: inline-block;
  height: 30px;
  width: 3px;
  background: url(../img/dotted_reply_line.gif) repeat-y;
  padding-right: 8px;
  vertical-align: bottom;
}

.reply_comment_section_content {
  display: inline-block;
  width: 580px;
  min-height: 100px;
  margin: 23px 13px 13px 13px;
  font-family: Arial;
  font-size: 14px;
  color: #000000;
}

.reply_comment_section_district {
  display: block;
  width: 620px;
  min-height: 100px;
  position: relative;
  background: #B6DEC4;
  text-align: left;
  margin: 0 auto;
}

.reply_comment_section {
  margin-left: 100px;
}

.reply_comment_section_top {
  width: 620px;
  text-align: left;
  margin: 0 auto;
}

span.reply_author_section {
  display: inline-block;
  font-family: Arial;
  font-size: 10px;
  width: 132px;
  text-align: left;
  border: 0px solid;
}

.reply_comment_section_col_left, .reply_comment_section_col_center, .reply_comment_section_col_right {
  display: inline-block;
  padding: 0;
  margin: 0;
  vertical-align: top;
  font-family: Arial;
  font-size: 13px;
  background: #B6DEC4;
  text-align: justify;
}

.reply_comment_section_left_border {
  display: block;
  border-left: 2px solid #B6DEC4;
  width: 11px;
  min-height: 100px;
}

.reply_comment_section_right_border {
  display: block;
  border-right: 2px solid #B6DEC4;
  width: 11px;
  min-height: 100px;
}

.reply_comment_section_center_up {
  display: block;
  border-top: 2px solid #B6DEC4;
  height: 11px;
  width: 594px;
}

.reply_comment_section_con_l_up {
  background: url("../img/reply_lightgreen_up_l.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 22;
}

.reply_comment_section_con_r_up {
  background: url("../img/reply_lightgreen_up_r.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 22;
}

.reply_comment_section_con_r_down {
  background: url("../img/reply_lightgreen_d_r.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 22;
}

.reply_comment_section_center_down {
  display: block;
  border-bottom: 2px solid #B6DEC4;
  height: 11px;
  width: 594px;
}

.reply_comment_section_con_l_down {
  background: #B6DEC4;
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
  left: 0;
  bottom: 0;
}

.reply_time_section_con_lup {
  display: inline-block;
  background: url("../img/reply_time_sector_con_lup.gif");
  height: 10px;
  width: 13px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
}

.reply_time_section_con_rup {
  display: inline-block;
  background: url("../img/reply_time_sector_con_rup.gif");
  height: 10px;
  width: 13px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
}

.reply_time_sector_center_bg {
  display: inline-block;
  background: url("../img/reply_time_sector_center_bg.gif");
  height: 10px;
  width: 164px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  position: relative;
}

.reply_time_sector_center_f {
  position: absolute;
  width: 164px;
  border: 0px solid red;
  top: 10px;
  background: #B6DEC4;
  z-index: 59;
}

.reply_time_sector_con_ldown {
  display: inline-block;
  background: url("../img/reply_time_sector_con_ldown.gif");
  height: 15px;
  width: 16px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  float: left;
}

.---_time_sector_border_down {
  display: inline-block;
  background: url("../img/reply_time_sector_border_down.gif") repeat-x;
  height: 4px;
  width: 132px;
  vertical-align: bottom;
  position: absolute;
  bottom: 0;
  left: 16px;
}

.reply_time_sector_con_rdown {
  display: inline-block;
  background: url("../img/reply_time_sector_con_rdown.gif");
  height: 15px;
  width: 16px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  float: right;
}

.reply_time_sector_border_down {
  display: inline-block;
  background: url("../img/reply_time_sector_border_down.gif") repeat-x;
  height: 4px;
  width: 132px;
  vertical-align: bottom;
  position: absolute;
  bottom: 0;
  left: 16px;
}

.reply_comm_sec_con_l {
  display: inline-block;
  width: 21px;
  height: 25px;
  background: url("../img/reply_main_com_repl_con_l.gif");
  padding: 0;
  margin: 0;
  vertical-align: top;
}

.reply_comm_sec_con_r {
  display: inline-block;
  width: 21px;
  height: 25px;
  background: url("../img/reply_main_com_repl_con_r.gif");
  padding: 0;
  margin: 0;
  vertical-align: top;
}

.reply_comment_section_reply_txt, .reply_comment_section_reply_txt a:link, .reply_comment_section_reply_txt a:visited {
  display: inline-block;
  width: 158px;
  height: 25px;
  line-height: 25px;
  background: #B6DEC4;
  color: #6CA211;
  font-family: Arial;
  font-size: 16px;
  padding: 0;
  margin: 0;
  vertical-align: top;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
}

.reply_comment_section_reply_txt a:hover {
  color: #000000;
  text-decoration: underline;
}

.adcom_shop_select_type1 {
  display: inline;
}

.adcom_shop_select_type2 {
  margin-left: 50px;
  display: inline;
}

.adcom_name {
  padding: 0;
  margin: 0;
}

.adcom_shop_select_name {
  padding: 0;
  margin: 0;
}

.add_comment_title {
  width: 620px;
  color: #000000;
  font-family: Arial;
  font-size: 18px;
  padding: 0;
  margin: 0;
  font-weight: bold;
  margin: 0 auto;
  text-align: left;
  display: inline-block;
  margin-left: 13px;
  margin-bottom: 5px;
}

.add_comment_section_center_up {
  display: block;
  border-top: 2px solid #D5F2CD;
  height: 11px;
  width: 594px;
}

.add_comment_section_con_r_down {
  background: url("../img/lightgreen_d_r.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
}

.add_comment_section_right_border {
  display: block;
  border-right: 2px solid #D5F2CD;
  width: 11px;
  min-height: 100px;
}

.add_comment_section_con_r_up {
  background: url("../img/lightgreen_up_r.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
}

.add_comment_section_center_down {
  display: block;
  border-bottom: 2px solid #D5F2CD;
  height: 11px;
  width: 594px;
}

.add_comment_section_center {
  display: block;
  width: 580px;
  min-height: 100px;
}

.add_comment_section_left_border {
  display: block;
  border-left: 2px solid #D5F2CD;
  width: 11px;
  min-height: 100px;
}

.add_comment_section_col_left, .add_comment_section_col_center, .add_comment_section_col_right {
  display: inline-block;
  padding: 0;
  margin: 0;
  vertical-align: top;
  font-family: Arial;
  font-size: 13px;
  background: #D5F2CD;
  text-align: justify;
}

.add_comment_section_con_l_up {
  background: url("../img/lightgreen_up_l.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
}

.add_comment_section_content {
  display: inline-block;
  width: 580px;
  min-height: 100px;
  margin: 13px 13px 13px 13px;
  margin-top: 13px;
  position: relative;
  background: #D5F2CD;
  /*border:2px solid red;*/
}

.add_comment_section_con_r_down {
  background: url("../img/lightgreen_d_r.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 22;
}

.add_comment_section_con_r_up {
  background: url("../img/lightgreen_up_r.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 22;
}

.add_comment_section {
  display: block;
  width: 620px;
  min-height: 100px;
  position: relative;
  background: #D5F2CD;
  text-align: left;
  margin: 0 auto;
}

.add_comment_section_con_l_up {
  background: url("../img/lightgreen_up_l.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 22;
}

.add_comment_section_con_l_down {
  background: #D5F2CD;
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
  background: url("../img/lightgreen_d_l.gif");
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 22;
}

.adcom_num_rait {
  display: inline-block;
  vertical-align: middle;
  font-family: Arial;
  font-size: 18px;
  color: #000000;
}

.adcom_num_rait #value5 {
  font-family: Arial;
  font-size: 18px;
  color: #000000;
  font-weight: bold;
}

.adcom_shop_stars {
  margin: 0;
  background: url("../img/full_star.png") repeat-x;
  width: 130px;
  height: 25px;
  display: table-cell;
  height: 26px;
}

.adcom_shop_exam {
  display: block;
  height: 100%;
  vertical-align: middle;
  padding: 5px 0px 5px 0px;
}

.adcom_shop_contact {
  padding: 0;
  margin: 0;
}

.adcome_weakness, .adcome_benefits {
  padding: 0;
  margin: 0;
}

.adcom_shop_notifyme {
  color: #000000;
  font-family: Arial;
  font-size: 14px;
  padding: 0;
  margin: 0;
  display: inline-block;
  line-height: 100%;
}

.adcom_shop_addimage {
  color: #6CA211;
  font-family: Arial;
  font-size: 14px;
  padding: 0;
  margin: 0;
}

.adcom_button:hover {
  border: 2px solid #77BE00;
  cursor: pointer;
}

.adcom_button {
  background: #ffffff;
  border: 2px solid #AACEAE;
  padding: 5px;
  border-radius: 8px;
  position: absolute;
  right: 0;
  bottom: 0;
  font-weight: bold;
  font-family: Arial;
  font-size: 13px;
}

/*****icon*****/
.tst_comment_section_content {
  display: inline-block;
  width: 580px;
  min-height: 100px;
  margin: 13px 13px 13px 13px;
  margin-top: 13px;
  position: relative;
  background: #D5F2CD;
  /*border:2px solid red;*/
}

.tst_comment_section_con_r_down {
  background: url("../img/lightgreen_d_r.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 22;
}

.tst_comment_section_con_r_up {
  background: url("../img/lightgreen_up_r.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 22;
}

.tst_comment_section {
  display: block;
  width: 620px;
  min-height: 100px;
  position: relative;
  background: #D5F2CD;
}

.tst_comment_section_con_l_up {
  background: url("../img/lightgreen_up_l.gif");
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 22;
}

.tst_comment_section_con_l_down {
  background: #D5F2CD;
  height: 13px;
  width: 13px;
  display: block;
  padding: 0;
  margin: 0;
  background: url("../img/lightgreen_d_l.gif");
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 22;
}

.inf_shop_icon {
  display: inline-block;
  background: url("../img/i.png") no-repeat;
  height: 18px;
  width: 18px;
  vertical-align: middle;
  margin-right: 2px;
}

.left_cellfingert {
  display: table;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: 0;
  vertical-align: middle;
}

.left_cellfinger {
  width: 15px;
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}

.left_ffinger {
  position: absolute;
  left: -12px;
  display: block;
  height: 90px;
  width: 13px;
  background: url("../img/left_finger.png") no-repeat;
}

.right_cellfingert {
  display: table;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  vertical-align: middle;
}

.right_cellfinger {
  width: 15px;
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}

.right_ffinger {
  position: absolute;
  right: -12px;
  display: block;
  height: 90px;
  width: 13px;
  background: url("../img/right_finger.png") no-repeat;
}

.shop_rating_nav_pages {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  display: inline-block;
  text-align: center;
}

.shop_rating_nav_pages a:link, .shop_rating_nav_pages a:visited {
  font-family: Arial;
  font-size: 16px;
  color: #FFFFFF;
  text-decoration: none;
  padding-left: 5px;
}

.shop_rating_nav_pages a:hover {
  color: #000000;
  text-decoration: none;
}

.allshop_link, a .allshop_link:link, a .allshop_link:visited {
  padding-right: 5px;
  display: inline-block;
  font-family: Arial;
  font-size: 16px;
  color: #FFFFFF;
  text-decoration: none;
  vertical-align: middle;
}

#shprlink:hover > .allshop_link {
  color: #000000;
}

.allshop_arrow {
  vertical-align: middle;
  width: 32px;
  height: 32px;
  display: inline-block;
  background: url("../img/allshop_arrow.png") no-repeat;
}

#shprlink:hover > .allshop_arrow {
  vertical-align: middle;
  width: 32px;
  height: 32px;
  display: inline-block;
  background: url("../img/allshop_arrow_hover.png") no-repeat;
}

.right_cellfinger:hover > .right_ffinger {
  cursor: pointer;
  background: url("../img/right_finger_hover.png") no-repeat;
}

.left_cellfinger:hover > .left_ffinger {
  cursor: pointer;
  background: url("../img/left_finger_hover.png") no-repeat;
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
  /* margin-left: 0; */
  /* border-top-left-radius: 4px; */
  /* border-bottom-left-radius: 4px; */
}

.pagination > li {
  float: left;
  display: table;
  margin: 0px 0px 15px 10px;
}

.pagination > li > * {
  display: table-cell;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  text-align: center;
  padding: 0 !important;
  float: none !important;
}

.pagination > li.disabled span, .pagination > li.disabled:hover span {
  background: none;
  border: 0;
  cursor: default;
}

.pagination > li.active span, .pagination > li.active:hover span {
  background: #7bb31f;
  border-radius: 50%;
  border: 0;
}

.pagination > li:first-child a, .pagination > li:first-child a:visited, .pagination > li:last-child a, .pagination > li:last-child a:visited {
  background: none;
  font-size: 24px;
  color: #6CA211;
}

.pagination > li:first-child a:hover, .pagination > li:last-child a:hover {
  color: #b6dec4;
  background: none;
  font-size: 24px;
}

.pagination > li a {
  /* padding: 6px 12px;*/
  border-radius: 50%;
  /*    box-shadow: 2 2 2px #D5F2CD;*/
  border: 0;
  color: #000;
}

.pagination > li a:hover {
  background: #b6dec4;
  color: #fff;
}

.pagination > li.active > a, .pagination > li.active > a:hover, .pagination > li.active > a:visited {
  background: #6CA211;
}

.message.success {
  border: 2px solid #6ca211;
  padding: 30px;
  background: rgba(119, 190, 0, 0.2);
  color: #6ca211;
  border-radius: 20px;
}

.message.error {
  border: 2px solid #d8686c;
  padding: 30px;
  background: rgba(255, 255, 255, 0.87);
  color: #d33c44;
  border-radius: 20px;
  margin: 30px 0;
}
