
.S {
  transition: 1s linear all;
  -webkit-transition: 1s linear all;
}

.fade.ng-enter {
  opacity: 0;
}

.fade.ng-enter.ng-enter-active {
  opacity: 1;
}

.fade.ng-leave {
  opacity: 1;
}

.fade.ng-leave.ng-leave-active {
  opacity: 0;
}