.add-review-section {
  display: block;
  max-width: 800px;
  min-height: 100px;
  position: relative;
  background: #D5F2CD;
  text-align: left;
  border-radius: 20px;
  padding: 23px;
  border: 2px solid #fff;

  .image-uploads{
    input{
      width: 100%;
    }
  }

  .typeahead-input {
    margin-bottom: 6px;
  }
  .typeahead-input, .typeahead-hint {
    height: 32px;
    font-size: 22px;
    width: 100%;
    background: #fff;
  }
  .typeahead-hint {
    color: #cecece;
  }
  .typeahead-results {
    width: 100%;
    padding: 5px 0px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #c1dcc3;
    box-shadow: 2px 4px 8px #c1dcc3;
  }
  .shop-selectable {
    background: #fff;
    display: block;
    font-size: 22px;
    padding: 0 10px;

    &:hover {
      cursor: pointer;
      background: #d8f3da;
      font-weight: 600;
    }

  }

  .checkbox{
    label{
      font-size: 16px;
    }
    .form-checkbox {
       box-shadow: none;
       height: 16px;
       width: 16px;
     }
  }

  #body{
    width: 100%;
  }

  a:link,a:visited{
    color: $mainColor;
    border-bottom: 1px dashed;
    letter-spacing: 1px;
    font-size: 16px;
  }
  a:hover{
    text-decoration: none;
  }

}

#preview-img-list > *{
  margin: 5px 0;
}