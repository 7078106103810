.article-page {

  h1{
    margin: 0;
  }
  .list-article-cell {
    background: #fff;
    border-radius: 10px;
    border: 2px solid;
    border-color: rgba(108, 162, 17, 0.37);
    margin-top: 20px;
    padding: 0 15px;
    display: block;

    &:visited, &:link {
      text-decoration: none;
      color: #000;
    }
    &:hover {
      text-decoration: none;
      color: #000;
    }

    &:hover .name{
      text-decoration: none;
      color: #000;
    }

    .short-body {
      margin-bottom: 20px;
      font-size: 14px;
      padding: 10px;
    }

    .body {
      margin-bottom: 20px;
      font-size: 16px;
      padding: 10px;
    }

    .name {
      padding: 10px;
      font-size: 16px;
      font-weight: 600;
      color: #6ca211;

      a:visited, a:link {
        color: #6ca211;
      }

      a:hover {
        color: #000;
        text-decoration: none;
      }
    }

    .author, .source, .date {
      .label {
        font-size: 14px;
        color: #000;
        font-weight: 600;
      }
    }

  }

  .full-article-cell {
    background: #fff;
    border-radius: 10px;
    border: 2px solid;
    border-color: rgba(123, 179, 31, 0.49);
    margin: 10px 0;
    line-height: 26px;
    padding: 10px 20px;

    .body {
      margin-bottom: 20px;
      font-size: 16px;
    }

    .name {
      font-size: 16px;
      font-weight: 600;

      a:visited, a:link {
        color: #6ca211;
      }

      a:hover {
        color: #000;
        text-decoration: none;
      }
    }

    .author, .source, .date {
      .label {
        font-size: 16px;
        color: #000;
        font-weight: 600;
      }
    }

  }

  a.back-link:link, a.back-link:visited {
    color: #6ca211;
    font-size: 18px;
    font-family: Roboto, Arial;
    display: inline-block;
    margin-top: 10px;
  }

  a.back-link:hover {
    color: #000;
  }

}

.article-block {
  max-width: 800px;
  padding: 8px;

  a:link, a:visited {
    width: 100%;
    display: block;
    height: 148px;
    text-decoration: none;
  }
  a:hover > .fa {
    color: #6ca211;
  }
  &:hover > div {
    opacity: 0.7;
  }

  .fa {
    position: absolute;
    bottom: 0px;
    right: 6px;
    font-size: 28px;
    color: #e8f5e9;
    transform: rotate(-45deg);
  }

  & > div {
    display: inline-block;
    text-align: left;
    background: #fff;
    border-radius: 20px;
    border: 6px solid;
    border-color: #e8f5e9;
    padding: 10px;
    min-height: 180px;

    &:hover {
      opacity: 1;
    }

  }

  .short-body {
    margin-top: 10px;
    font-size: 13px;
    word-break: break-all;
    color: #000;
  }

  .name {
    font-size: 14px;
    font-weight: 600;
    color: #6ca211;

    a:visited, a:link {
      color: #6ca211;
    }

    a:hover {
      color: #000;
    }
  }

}