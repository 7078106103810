#myModal {
  text-align: center;
  .modal-dialog {
    width: auto;
    display: inline-block;
    button.close{
      content: "x";
      position: absolute;
      background: #000;
      opacity: .6;
      z-index: 2;
      top: 0px;
      right: 0px;
      width: 38px;
      border-radius: 0 15px 0 15px;
      height: 36px;
      font-size: 23px;
      line-height: 30px;
      color: #fff;
      cursor: pointer;
      &:hover{
        opacity: .9;
      }
    }
  }
  .modal-content{
    overflow: hidden;
    background: transparent;
    border-radius: 15px;
  }
}

.carousel-indicators {
  li {
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    background-size: cover;
  }
}

.carousel-inner {
  border-radius: 15px;
  img {
    width: 100%;
    border-radius: 15px;
  }
}

.carousel-indicators {
  width: 100%;
  text-align: center;
  list-style: none;
  margin: 0;
  left: 0;

  li, li.active {
    bottom: 0;
    display: inline-block;
    border: 0;
    width: auto;
    height: auto;
    margin: 0 10px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.45);

  }
  li:hover{
    bottom: 10px;
    position: relative;
    transition: all 300ms linear;
  }
  li{
    bottom: 0;
    position: relative;
    transition: all 300ms linear;
  }
  .img-box {
    border: none;
    margin: 0;
    &:after {
      display: none;
    }
  }

  li:hover .img-box, li.active .img-box {
    border: 2px solid #fff;
  }
}