
.shop-page {

  h1{
    padding: 0;
    margin: 0;
    font-size: inherit;
  }
  /* width: $shop-page-width;*/
  /*margin-top: 120px;*/

  .shop-description{
    padding: 0 15px 10px 16px;
  }

  .shop-title {
    display: inline-block;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    background-color: #6CA211;
    font-size: 18px;
    color: #fff;
    padding: 6px 0;
  }

  .shop-rating {
    text-align: center;
  }

  .shop-info{
    .shop-detail-rating{
      padding-left: 16px;
    }
    .name-rating, .name-link {
      color: #000;
    }
    .number-block {
      font-weight: 800;
      color: #ffb000;
      padding: 8px 22px;
      text-align: center;
      background: #fff;
      border-radius: 10px;
      font-size: 33px;
      display: inline-block;
      margin-bottom: 10px;
    }
    .max-rate{
      color: #6ca211;
      font-size: 28px;
      font-weight: 600;
    }
    .comments-count {
      color: #6ca211;

    }
    .shop-link {
      //padding-left: 16px;
     }
  }



  .shop-info {
    padding: 20px 20px;
    font-size: $caption-fsize;
    text-align: left;
  }

  .shop-overall-rating {

    margin-top: 20px;

  }

  .shop-two-box {
    overflow: hidden;
    margin: 0 auto;
    padding-top: 50px;

  }

  .shop-good-caption {

    text-align: center;
    color: #77BE00;
    font-size: 18px;
    padding-bottom: 10px;

  }

  .shop-bad-caption {

    text-align: center;
    color: #6F6F6F;
    font-size: 18px;
    padding-bottom: 10px;

  }

  .comment-add-section {
    h2 {
      color: #6CA211;
      font-size: 18px;
      text-align: left;
      margin-top: 60px;
    }
  }

  .bootstrap-tagsinput {
    border: 0;
    background: none;
    box-shadow: none;

    span {
      letter-spacing: 3px;
      font-size: 13px;
    }

    input {
      background: #fff;
    }

  }

  button.new-review {
    border-radius: 10px;
    margin-top: 10px;
    min-width: 100px;
    height: 38px;
    text-transform: uppercase;
    background: white;
    border: 2px solid #b6dec4;
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: bold;
    color: #555;
    box-shadow: 1px 4px 1px rgba(0, 0, 0, 0.075);

    &:hover {
      background: #b6dec4;
      color: #fff;
      border-color: #fff;
    }

  }

  .advantages-box {
    padding: 10px;
    /*width: 300px;*/
    min-height: 300px;
    background-color: #E0F0C4;
    border-radius: 20px;
    margin: 0 auto;

    span {
      padding: 2px 4px;
      letter-spacing: 3px;
      font-size: 13px;
      margin: 5px 5px;
      line-height: 26px;
      white-space: normal;
    }

  }

  .disadvantages-box {
    padding: 10px;
   /* width: 300px;*/
    min-height: 300px;
    background-color: #D3D3D3;
    border-radius: 20px;
    margin: 0 auto;
    span {
      padding: 2px 4px;
      letter-spacing: 3px;
      font-size: 13px;
      margin: 5px 5px;
      line-height: 26px;
      white-space: normal;
    }

  }

  .back-all-comment-link{
    color: #ffffff;
    font-size: 14px;
    padding: 10px;
    border-radius: 15px;
    background: #7bb31f;
    text-decoration: none;
    &:hover{
      background: rgba(123, 179, 31, 0.8);
      cursor: pointer;
    }
  }
}