$replyBG: #AACEAE;
$replyBG2: #b6dec4;

#scrollable-dropdown-menu{
  margin-top: 10px;
}

label {
  display: block;
}

.label-inline {
  display: inline-block;
}

input {
  display: inline-block;
  margin-left: 0px;
  padding-left: 0px;
  position: relative;
  border-radius: 8px;
  padding: 4px;
  border: 1px solid #AACEAE;
}

.field-w-4 {
  width: 60%;
}

.field-y-3 {
  height: 36px;
}

//Add new shop form

.add-shop-section {

  max-width: 620px;

  .section-content {
    width: 100%;
    min-height: 300px;
    border-radius: 20px;
    background-color: $replyBG2;
    padding: 44px 22px;
    text-align: left;
    margin: 0 auto;
  }

}

//New Comments Section
.comment-add-section {

  h3 {

    text-align: left;

  }

}

//Comment's Reply section

.comment-reply-active {
  background-color: #D5F2CD !important;
  color: #8bbb9c !important;
  border-color: #8bbb9c !important;
  cursor: pointer;
  box-shadow: 2px 1px 0px #8bbb9c;
}

.write-reply-comment-content {
  width: 100%;
  min-height: 100px;
  border-radius: 20px;
  background-color: $replyBG2;
  padding: 23px 13px;
  text-align: left;
  margin: 0 auto;
}

.reply-comment-section {

  display: block;
  max-width: 620px;
  min-height: 100px;
  position: relative;
  text-align: left;
  margin: 0 auto;

}

.write-comment-title {

  font-size: 20px;
  text-transform: uppercase;
  color: $replyBG2;
}

.comment-reply-form {
  display: none;
  position: relative;
  .ver-pointed-line {
    width: 2px;
    height: 100%;
    border-left: 2px dashed #AACEAE;
    display: block;
    position: absolute;
    top: -25px;
    left: 52px;
  }

  legend {
    border: 0;
    margin-left: 48px;
  }

  .reply-comment-section {

    .title-form-comment {

      letter-spacing: 2px;
      color: #fff;
    }

    h3 {

      text-align: left;

    }

    label {

      display: block;

    }

    textarea {

      width: 96%;
      display: block;

    }

    textarea:focus, input[type="text"]:focus, select:focus {
      border-radius: 8px;
      outline: none;
      border: 1px solid #ffffff;
      box-shadow: 0 0 5px #555;
    }

    input[type='text'] {
      display: inline-block;
      margin-left: 0px;
      padding-left: 0px;
      position: relative;
      border-radius: 8px;
      padding: 4px;
      border: 1px solid #AACEAE;

    }

    button {

      border-radius: 10px;
      margin-top: 10px;
      min-width: 100px;
      height: 38px;
      text-transform: uppercase;
      background: white;
      border: 2px solid #b6dec4;
      letter-spacing: 2px;
      font-size: 14px;
      font-weight: bold;
      color: #555;
      box-shadow: 1px 4px 1px rgba(0, 0, 0, .075);
      &:hover {
        background: $replyBG2;
        color: #fff;
        border-color: #fff;
      }

    }

  }

}

.teb-reply {
  margin-left: 48px;
}

.alert {
  display: none;
  &.active {
    display: block;
  }
}

.show-overall-estimate {
  font-size: 22px;
}

.overall-estimate {
  float: left;
  color: #ffb000;
  font-size: 22px;
  margin-right: 4px;
}

.rating-reset {
  float: left;
  margin: 0 6px;
  color: #dd0000;
  font-size: 14px;
  &:hover ~ .overall-estimate {
    color: #dd0000;
  }
}

.submit-btn {
  background: #7bb31f; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border: 2px solid #D5F2CD;

  &:hover {
    border: 2px solid #ffffff;
    background: #709c0f;
  }

}

.glyphicon:hover {
  cursor: pointer;
}