.logo-sm-ru {
  display: block;
  background: url("/img/logo-sm_ru.png") no-repeat;
  width: 135px;
  height: 44px;
  margin: 8px 12px;
}
.logo-sm-en {
  display: block;
  background: url("/img/logo-sm_en.png") no-repeat;
  width: 135px;
  height: 44px;
  margin: 8px 12px;
}

.navi-stripe {
  display: table;
  width: 100%;
  & > div {
    width: 70%;
    display: table-cell;
    vertical-align: middle;
  }
  & > div:first-child {
    width: 30%;
    display: table-cell;
    vertical-align: middle;
  }
}

.mobile-icons {
  a:link, a:visited {
    color: #fff;
    font-size: 28px;
    display: inline-block;
  }
  .active, a:hover {
    color: #ffb000;
  }
  ul {
    display: inline-block;
    li {
      float: left;
      list-style: none;
      padding: 4px 9px;
      height: 45px;
    }
    li.finder{
      background: #6CA211;
      a:link, a:visited {
        color: #fff;
        font-size: 28px;
        display: inline-block;
      }
      .active, a:hover {
        color: #ffb000;
      }
    }
    margin: 0;
    padding: 0;
  }
.tt-menu{
  width: 220px;
}
}

#search-shop-mobile {
  display: inline-block;
  width: 100%;
  input {
    width: 100%;
    height: 37px;
    border: 0;
  }
  .tt-selectable:hover{
    cursor: pointer;
  }
  .shop-result-name{
    a:visited,a:link{
      color: #000;
    }
    a:hover{
      text-decoration: none;
    }
  }
}

.mobile-search{
  background: #6CA211;
  padding: 8px 15px;
  .twitter-typeahead{
    width: 100%;
  }
}

.mobile-add-review {
  margin-top: 15px;
  max-width: 100%;
}

@media (max-width: 992px) {
  .shop-title{
    margin-top: 20px;
  }
  .content-layout{
    max-width: 100%;
  }
  .about{
    border: 0;
    box-shadow: none;
  }
  .agreement{
    border-bottom: 1px solid #fff;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  .footer{
    max-width: 620px;
  }
}