.shop-list{
  width: 620px;

  .shop-parent-box{
    margin: 20px 0;
  }

 .shop-box{
   width: 100%;
   min-height: 100px;
   border-radius: 20px;
   background-color: #D5F2CD;
   padding: 23px 13px;
   text-align: left;
   margin: 0 auto;
   border: 2px solid #ffffff;
    }

}

.shop-rating-stars{
  color: #ffb000;
  font-size: 16px;
  display: inline-block;
}


